import {
  ENFORCEMENT_OFFICER,
  SYSTEM_ADMINISTRATOR,
  SYSTEM_MAINTAINER,
  TVL_ADMIN,
  CVSC_ADMIN
} from '../../auth';

import { USER_RECEIVED } from '../action-types';

const initialState = null;

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_RECEIVED:
      const groups = action.user.vss_groups;
      const user_with_roles = {
        ...action.user,
        isEnforcementOfficer: groups.indexOf(ENFORCEMENT_OFFICER) !== -1,
        isSystemMaintainer: groups.indexOf(SYSTEM_MAINTAINER) !== -1,
        isSystemAdministrator: groups.indexOf(SYSTEM_ADMINISTRATOR) !== -1,
        isTvlAdmin: groups.indexOf(TVL_ADMIN) !== -1,
        isCvscAdmin: groups.indexOf(CVSC_ADMIN) !== -1
      };

      return {
        ...state,
        ...user_with_roles
      };
    default:
      return state;
  }
};

export default userReducer;
