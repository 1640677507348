import { PASSAGES_RECEIVED } from '../action-types';

const initialState = {};

const passagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case PASSAGES_RECEIVED:
      return {
        ...state,
        [action.scid]: action.passages
      };
    default:
      return state;
  }
};

export default passagesReducer;
