import { DataGrid as MuiDataGrid } from '@mui/x-data-grid';

const DataGrid = ({ rows, columns, rowId, processRowUpdate, processRowError, ...rest }) => {
  return (
    <MuiDataGrid 
      getRowId={rowId} 
      rows={rows} 
      columns={columns} 
      processRowUpdate={processRowUpdate} 
      onProcessRowUpdateError={processRowError}
      disableColumnMenu
      disableColumnFilter
      autoHeight
      editMode="row"
      hideFooter
      sx={{
        '& .MuiDataGrid-columnHeaders': {
          backgroundColor: (theme) => theme.palette.primary.background,
          color: (theme) => theme.palette.primary.text
        }
      }}
      {...rest}
    />
  )
}

export default DataGrid;

