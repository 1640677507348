import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';

import { searchSafetyCentres, selectSafetyCentre } from '@actions';
import { SelectSafetyCenterModal } from '@components/Modal';

const SafetyCentreSelection = () => {
  const dispatch = useDispatch();
  const safetyCentres = useSelector((state) => {
    if (state.configuration.safetyCentres !== undefined) {
      const values = Object.values(state.configuration.safetyCentres).filter(s => s.approaches && s.approaches.length > 0 );
      return values.sort((a, b) => b.location.lat - a.location.lat)
    } else {
      return state.configuration.safetyCentres;
    }
  }, shallowEqual);

  useEffect(() => {
    dispatch(searchSafetyCentres());
  }, [dispatch]);

  const select = (id) => {
    dispatch(selectSafetyCentre(id));
  };

  if (!safetyCentres) {
    return null;
  }

  const links = safetyCentres.map((centre) => (
    <MenuItem className="justify-center" key={centre.id}>
      <Link onClick={() => select(centre.id)} to={``}>
        {centre.name}
      </Link>
    </MenuItem>
  ));

  const configured = safetyCentres.length > 0;

  if (safetyCentres.length === 0) {
    return (
      <div className="flex flex-col items-center space-y-4">
        <h1 className="text-xl font-bold">There are no configured Safety Centres</h1>
      </div>
    );
  }

  return (
    <SelectSafetyCenterModal 
      open={true}
      title="Select a Safety Centre"
    >
      {configured && links}
      {!configured && <p>There are no Safety Centres configured</p>}
    </SelectSafetyCenterModal>
  );
};

export default SafetyCentreSelection;
