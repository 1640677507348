import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';

export default function FullPageLoader({ message, open, disableSpinner  }) {

  return (
    <Backdrop
      sx={{ 
        backgroundColor: (theme) => theme.palette.primary.text,
        color: (theme) => theme.palette.primary.mian, 
        zIndex: (theme) => theme.zIndex.drawer + 1 
      }}
      open={open}
    > 
      { disableSpinner ? null : <CircularProgress color="secondary" /> }
      <Typography className="pl-8" variant='h5' color="primary">{message}</Typography>
    </Backdrop>
  );
}