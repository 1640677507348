import {
  DELETE_TARGETED_VEHICLE,
  RECEIVE_TARGETED_VEHICLES
} from '../action-types';

const initialState = {};

const targetedVehiclesReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_TARGETED_VEHICLES:
      return {
        ...state,
        vehicles: action.targetedVehicles.items
      };
    case DELETE_TARGETED_VEHICLE:
      return {
        ...state,
        vehicles: state.vehicles.filter(tv => tv.id !== action.id)
      };
    default:
      return state;
  }
};

export default targetedVehiclesReducer;
