import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { format, parseISO, subHours, isAfter }from 'date-fns';
import { utcToZonedTime } from "date-fns-tz";
import { Button } from '@mui/material';

import { VmsButton } from './Button';
import { toHumanReadableFailureType, parseTargetedVehicleData } from '@helpers';
import { saveTargetedVehicle } from '@actions';
import { TargetedVehicleModal } from '@components/Modal';
import { StyledTableCell } from '@components/Table';

const columns = [
  { id: 'safetyCentreName', label: 'Safety Centre', minWidth: 170 },
  { id: 'numberPlate', label: 'Plate', minWidth: 100 },
  { id: 'approachName', label: 'Approach', minWidth: 170, format: (value) => value ? value : "Weigh Station" },
  { id: 'captured', label: 'Captured', minWidth: 170, format: (value) => format(utcToZonedTime(value, "Pacific/Auckland"), 'dd/MM/yyyy HH:mm:ss')},
  { id: 'assessmentFailureTypes', label: 'Assessments', minWidth: 170, format: (value) => toHumanReadableFailureType(value)},
  { id: 'recall', label: 'Recall', minWidth: 140, },
  { id: 'details', label: 'Details', minWidth: 140, },
];

const RecallButton = ({ captured, recallModalClick, numberPlate }) => {
  const isWithin24Hours = isAfter(parseISO(captured), subHours(Date.now(), 24));
  if (isWithin24Hours) {
    return <VmsButton text="Mark For Recall" size="small" color="secondary" onClick={() => recallModalClick(numberPlate)} />
  }
}

export const AssessmentTable = ({
  measuredCombinations,
  cursor,
  showLinkToDetails,
  renderToPrint,
  loadMoreData,
  user,
}) => {
  const [modalOpen, toggleModal] = useState(false);
  const [recallVehicleInitialValues, setInitialValues] = useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const targetedVehicleDefaultValues = useSelector(state => state.globalParameters.targetedVehicle);

  const handleModal = (numberPlate) => {
    setInitialValues({
      numberPlate: numberPlate ? numberPlate : '',
      category: "recall",
      subcategories: [],
      reason: "",
      requesterName: user.name,
      requesterEmail: user.email
    })
    
    toggleModal(!modalOpen)
  }

  const handleAddForRecall = async (data) => {
    const targetedVehicle = parseTargetedVehicleData(data);

    try {
      await dispatch(saveTargetedVehicle(targetedVehicle)) ;
    } catch (e) {
      console.error("Unable to add recall vehicle: ", e);
    } finally {
      handleModal();
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleLoadMoreData = async () => {
    const lastPage = Math.ceil(measuredCombinations.length / rowsPerPage) - 1;
    if (page === lastPage) {
      try {
        dispatch(loadMoreData())
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };

  return (
    <div className="flex flex-col">
      <Paper >
        <TableContainer >
          <Table stickyHeader >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              { measuredCombinations?.length > 0 ? 
                measuredCombinations
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow hover tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = column.id === 'recall' ?  
                        <RecallButton captured={row.captured} recallModalClick={handleModal} numberPlate={row.numberPlate} /> :
                        (column.id === 'details' && !!showLinkToDetails) ? 
                          <Button onClick={() => navigate(`/assessments/${row.id}`)}>View Details</Button> :
                        row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {
                            column.format ? 
                            column.format(value) : 
                            value
                          }
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))
                :
                <TableRow>
                  <TableCell colSpan={7}>No vehicle records to display</TableCell> 
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={measuredCombinations?.length ? measuredCombinations.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={(props) => {
            return (
              <div className='flex justify-center items-center pr-12'>
                <TablePaginationActions
                  {...props}
                  // handleLoadMoreData={handleLoadMoreData}
                />
                { page >= Math.ceil(measuredCombinations?.length ? measuredCombinations.length / rowsPerPage : 1) - 1 &&
                  <div className='flex items-center w-80'>
                    <Button onClick={handleLoadMoreData} disabled={!cursor} >Load More</Button>
                  </div>
                }
              </div>
            );
          }}
        />
      </Paper>
     
      { renderToPrint ? null : 
        <TargetedVehicleModal 
          title="Mark for recall" 
          formType="recall"
          open={modalOpen}
          disabledPlate={true}
          disabledCategory={true}
          disabledExpiry={true}
          onCancel={handleModal}
          onAdd={handleAddForRecall}
          initialValues={recallVehicleInitialValues}
          targetedVehicleDefaultValues={targetedVehicleDefaultValues}
        />
      }
    </div>
  );
};