import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Outlet } from 'react-router-dom';

import FullPageLoader from '@components/Loader';
import { retrieveUser } from '@actions';

export const RequiredAuth = () => {
  const user = useSelector((state) => state.user);

  const { oktaAuth, authState } = useOktaAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!authState) {
      return;
    }

    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(window.location.href, window.location.origin);
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    }
    // eslint-disable-next-line
  }, [oktaAuth, !!authState, authState?.isAuthenticated]);

  if (!authState?.isAuthenticated) {
    
    return <FullPageLoader open message="Loading configuration..." />;
  }

  if (authState?.isAuthenticated && !user) {
    dispatch(retrieveUser(oktaAuth));
    return <FullPageLoader open message="Waiting for user information" />;
  }

  return (<Outlet />);
}