import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { StyledTableCell } from '@components/Table';

export const PermitsList = ({ measuredCombination }) => {
  if (!measuredCombination) return null;

  const permits = measuredCombination.permittedVehicleCombinations;

  if (!permits) {
    return <p>No current permits were found.</p>;
  }

  const permitColumns = [
    { id: 'permitType', label: 'Type' },
    { id: 'permittedGrossMassKg', label: 'Gross Weight', align: "center" },
    { id: 'permitNumber', label: 'Permit Number', align: "center" },
  ];

  return (
    <TableContainer >
      <Table stickyHeader >
        <TableHead>
          <TableRow>
            {permitColumns.map((column) => (
              <StyledTableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                {column.label}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {permits
            .map((row, i) => {
              return (
                <TableRow hover tabIndex={-1} key={i}>
                  {permitColumns.map((column) => {
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {row[column.id]}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};