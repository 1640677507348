import React from 'react';
import {styled} from '@mui/material/styles';
import { format } from 'date-fns';
import { orderBy } from '@helpers';

export const VehicleDetails = ({  mvrVehicle }) => {
  const orderedStickersByDate = orderBy(mvrVehicle.vehicleNotices, [sticker => new Date(sticker.alertDate)], ['desc']);
  const latestSticker = orderedStickersByDate ? orderedStickersByDate[0] : null;
  
  return (
    <>
      <VehicleDetailsRow>
        <VehicleDetailsLabel>{mvrVehicle.inspectionType ? mvrVehicle.inspectionType : 'COF/WOF'}</VehicleDetailsLabel>
        <VehicleDetailsContent>
          {mvrVehicle.hasInspection
            ? (
              <>
                <VehicleDetailsContentRow>
                  <span>Pass/failed</span>
                  <span>{mvrVehicle.inspectionResult}</span>
                </VehicleDetailsContentRow>
                <VehicleDetailsContentRow>
                  <span>Date of inspection</span>
                  <span>{format(new Date(mvrVehicle.inspectionDate), 'd MMM yyyy')}</span>
                </VehicleDetailsContentRow>
                <VehicleDetailsContentRow>
                  <span>Expiry date</span>
                  <span>{format(new Date(mvrVehicle.inspectionExpiryDate), 'd MMM yyyy')}</span>
                </VehicleDetailsContentRow>
                <VehicleDetailsContentRow>
                  <span>Inspection authority</span>
                  <span>{mvrVehicle.inspectionAuthority}</span>
                </VehicleDetailsContentRow>
              </>  
            )
            : <span>Vehicle does not have a current COF/WOF</span>
          }  
        </VehicleDetailsContent>
      </VehicleDetailsRow>
      <VehicleDetailsRow>
        <VehicleDetailsLabel>
          RUC licence
          <VehicleDetailsLabelHelper>(Prime Mover)</VehicleDetailsLabelHelper>
        </VehicleDetailsLabel>
        <VehicleDetailsContent>
          {mvrVehicle.hasCurrentRUCLicence
            ? (
              <>
                <VehicleDetailsContentRow>
                  <span>RUC licence number</span>
                  <span>{mvrVehicle.numberPlate == null ? "" : mvrVehicle.rucLicenceNumber}</span>
                </VehicleDetailsContentRow>
                <VehicleDetailsContentRow>
                  <span>RUC licence type</span>
                  <span>{mvrVehicle.rucLicenceType}</span>
                </VehicleDetailsContentRow>
                <VehicleDetailsContentRow>
                  <span>Distance</span>
                  <span>{mvrVehicle.rucEndDistance} km</span>
                </VehicleDetailsContentRow>
                <VehicleDetailsContentRow>
                  <span>Weight</span>
                  <span>{mvrVehicle.rucWeight} kg</span>
                </VehicleDetailsContentRow>
                <VehicleDetailsContentRow>
                  <span>Paid/unpaid</span>
                  <span>{mvrVehicle.rucLicenceIsPaid ? 'Paid' : 'Unpaid'}</span>
                </VehicleDetailsContentRow>
              </>
            )
            : <span>Vehicle does not have current RUC license</span>
          }
        </VehicleDetailsContent>
      </VehicleDetailsRow>
      <VehicleDetailsRow>
        <VehicleDetailsLabel>
          Vehicle notice
          {latestSticker && (
            <VehicleDetailsLabelHelper>(Total stickers: {orderedStickersByDate.length})</VehicleDetailsLabelHelper>
          )}
        </VehicleDetailsLabel>
        <VehicleDetailsContent>
          {latestSticker
            ? (
              <>
                <VehicleDetailsContentRow>
                  <span>Date issued</span>
                  <span>{format(new Date(latestSticker.alertDate), 'd MMM yyyy')}</span>
                </VehicleDetailsContentRow>
                <VehicleDetailsContentRow>
                  <span>Sticker type</span>
                  <span>{latestSticker.stickerType}</span>
                </VehicleDetailsContentRow>
                <VehicleDetailsContentRow>
                  <span>Reason</span>
                  <span>{latestSticker.stickerReason}</span>
                </VehicleDetailsContentRow>
              </>
            )
            : <span>Vehicle does not have a non-operation order (sticker)</span>
          }
        </VehicleDetailsContent>
      </VehicleDetailsRow>
      <VehicleDetailsRow>
        <VehicleDetailsLabel>Vehicle licence</VehicleDetailsLabel>
        <VehicleDetailsContent>
          {mvrVehicle.hasCurrentVehicleLicence
            ? (
              <>
                <VehicleDetailsContentRow>
                  <span>Licence number</span>
                  <span>{mvrVehicle.numberPlate == null ? "" : mvrVehicle.licenceNumber}</span>
                </VehicleDetailsContentRow>
                <VehicleDetailsContentRow>
                  <span>Licence type</span>
                  <span>{mvrVehicle.licenceType}</span>
                </VehicleDetailsContentRow>
                <VehicleDetailsContentRow>
                  <span>Expiry date</span>
                  <span>{format(new Date(mvrVehicle.licenceExpiryDate), 'd MMM yyyy')}</span>
                </VehicleDetailsContentRow>
              </>
            )
            : <span>Vehicle is not licensed</span>
          }
        </VehicleDetailsContent>
      </VehicleDetailsRow>
    </>
  )
};


const VehicleDetailsRow = styled("div")`
  border-bottom: 1px solid gray;
  display: flex;
  color: black;
  max-width: 700px;
  padding: 1.3rem 0;

  &:first-of-type {
    padding-top: 0;
  }
`;

const VehicleDetailsLabel = styled("div")`
  font-size: 16px;
  font-weight: bold;
  flex: 2.5;
`;

const VehicleDetailsLabelHelper = styled("span")`
  display: block;
  font-size: 14px;
  font-weight: normal;
`;

const VehicleDetailsContent = styled("div")`
  flex: 7.5;
`;

const VehicleDetailsContentRow = styled("div")`
  display: flex;
  margin-bottom: 0.75rem;

  &:last-child {
    margin-bottom: 0;
  }

  span {

    &:first-of-type {
      font-weight: bold;
      flex: 3.5;
    }

    &:last-child {
      flex: 6.5;
    }
  }
`;