import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import 'react-toastify/dist/ReactToastify.min.css';
import { v4 as uuidv4 } from 'uuid';
import { Buffer } from 'buffer';

import store from './redux/store';
import theme from './theme';
import App from './App';
import './styles/index.css';
import ErrorBoundary from '@containers/ErrorBoundary';

const nonce = Buffer.from(uuidv4()).toString('base64');

const cache = createCache({
  key: 'prefix-key',
  nonce: nonce,
  insertionPoint: document.getElementById('emotion-insertion-point'),
  prepend: true,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <CacheProvider value={cache}>
      <ThemeProvider theme={theme}>
        <Provider  store={store} >
          <ErrorBoundary>
            <App />
            <ToastContainer style={{ width: "500px" }}/>
          </ErrorBoundary>
        </Provider>
      </ThemeProvider>
    </CacheProvider>
  </React.StrictMode>
);