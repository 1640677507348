import { GLOBAL_PARAMETERS_RECEIVED } from '../action-types';

const initialState = {
  targetedVehicle: []
};

const globalParametersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GLOBAL_PARAMETERS_RECEIVED:
      return {
        ...state, 
        targetedVehicle: action.globalParameters
      };
    default:
      return state;
  }
};

export default globalParametersReducer;
