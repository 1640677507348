import React from 'react';
import { styled } from '@mui/material/styles';
import { format, parseISO } from 'date-fns';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const DetailsHeader = ({ capturedDate, measurementSource }) => {
  const { safetyCentre } = measurementSource;
  const date = format(parseISO(capturedDate), 'dd-MM-yyyy:HH:mm');
  let { mcid } = useParams();

  const searchItems = useSelector(state => state.vehicleSearchResultItems);

  const getSiteApproachName = () => {
    if (searchItems.items) { 
      const approach = searchItems.items.find((a) => a.id === mcid);
      if (approach !== undefined) {
        return approach.approachName
      } else {
        return "Unknown";
      }
    };
    return false;
  }
  const siteApproachName = getSiteApproachName();

  return (
    <DetailHeaderRow direction="FlexConatiner">
      <div style={{paddingLeft: 0}}>
        <DetailTitle>Safety Centre:&nbsp;
          <SpanWeight >&nbsp;&nbsp;{ safetyCentre ? safetyCentre : 'Unknown' }</SpanWeight>
        </DetailTitle>
      </div>
      <div>
        <DetailTitle>Approach:&nbsp;
          <SpanWeight >&nbsp;&nbsp;{ siteApproachName ? siteApproachName : 'Unknown' }</SpanWeight>
        </DetailTitle>
      </div>
      <div>
        <DetailTitle>Captured:&nbsp;
          <SpanWeight >&nbsp;&nbsp;{ date ? date : 'Unknown' }</SpanWeight>
        </DetailTitle>
      </div>
    </DetailHeaderRow>
  )
}

const DetailHeaderRow = styled("div")`
  display: flex;
  flex-direction: row;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  padding: 0;
`;

const DetailTitle = styled("p")`
  margin-right: 1em;
  font-weight: 800;
`;

const SpanWeight = styled("span")`
  font-weight: normal;
`;

export default DetailsHeader