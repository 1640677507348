import * as React from 'react';
import clsx from 'clsx';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { VmsIconButton } from './Button';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/material/styles';

import AllApproachStatusDropdown from './AllApproachStatusDropdown';

const Logo = styled('img')({
  width: '245px',
  height: '24px',
});

const Header = ({ title, onMenuClick, isLoggedOut=false, ...props }) => {
  return (
    <AppBar
      id="header"
      className={clsx('flex relative z-20 shadow-md', props.className)}
      sx={{
        backgroundColor: (theme) => theme.palette.primary.dark,
        height: '64px',
        padding: '0',
      }}
      position="static"
    >
      <Toolbar sx={{ 
        paddingLeft: (theme) => `${theme.spacing(2)} !important`, 
        paddingRight: (theme) => `${theme.spacing(2)} !important`, 
        justifyContent: 'space-between' 
      }}>
        <div className='flex items-center'>
          {(isLoggedOut) ? null : <AllApproachStatusDropdown />}                                                                                
          <Typography variant="h3" component="div" sx={{ flexGrow: 1 , marginLeft: 2 }} >{title}</Typography>
        </div>

        <Logo src="/images/logo.png" />

        <VmsIconButton
          icon={MenuIcon}
          sx={{ mr: 0, padding: 0, color: (theme) => theme.palette.primary.text }}
          color="primary"
          aria-label="menu"
          onClick={onMenuClick}
        />
        
      </Toolbar>
  </AppBar>
  )
}

export default Header;