import { ERROR_CLEARED, ERROR_RAISED } from '../action-types';

const initialState = {};

const errorReducer = (state = initialState, action) => {
  switch (action.type) {
    case ERROR_RAISED:
      return {
        title: action.title,
        message: action.message
      };
    case ERROR_CLEARED:
      return {};
    default:
      return state;
  }
};

export default errorReducer;
