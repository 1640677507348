import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography, List, ListItem, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledListItem = styled(ListItem)(({ theme }) => ({
    '& .MuiListItemText-root': {
        padding: 0,
        margin: 0,
    },
}));

const data = [
    {
        numberPlate: 'ABC123',
        expiry: '01/06/2021',
        reason: 'Police Operation',
        category: 'targeted',
        subcategories: 'vehicleDefects overDimension',
        requesterName: 'Tom Smith',
        requesterEmail: 'tom.smith@police.govt.nz',
    },
    {
        numberPlate: '1234567',
        expiry: '01/06/2021',
        reason: 'Police Operation',
        category: 'targetedTsl',
        subcategories: 'ruc permit',
        requesterName: 'Tom Smith',
        requesterEmail: 'tom.smith@police.govt.nz',
    },
];

export default function CsvUpload ({ count, error }) {
    if (count === 0 && !error) {
        return (<>
            <Typography className="pt-8 pb-4" variant="h3" >Add/Update a list of Targeted Vehicles by selecting a CSV file structured as in the below example.</Typography>
            <List dense={true}>
                <StyledListItem>
                    <ListItemText primary="The first line assumed to be a header row. Assure that is in the correct format as shown in the table below" />
                </StyledListItem>
                <StyledListItem>
                    <ListItemText primary="Required - number plate - between 1 and 6 characters or TSL numbers of 5 or 7 digits" />
                </StyledListItem>
                <StyledListItem>
                    <ListItemText primary="Required - expiry date in format dd/mm/yyyy" />
                </StyledListItem>
                <StyledListItem>
                    <ListItemText primary="Required - reason text" />
                </StyledListItem>
                <StyledListItem>
                    <ListItemText primary="Required - category (targeted, targetedTsl). Cannot add recall vehicles using this form" />
                </StyledListItem>
                <StyledListItem>
                    <ListItemText primary="Required - subcategories, one of the following without the comma character: (dangerousGoods, driver, operator, loadSecurity, overDimension, permit, ruc, speed, vehicleDefects, weight, driverChangeOver)" />
                </StyledListItem>
                <StyledListItem>
                    <ListItemText primary="Required - requester name" />
                </StyledListItem>
                <StyledListItem>
                    <ListItemText primary="Required - requester email (@nzta.govt.nz or @police.govt.nz)" />
                </StyledListItem>
            </List>
            <TableContainer component={Paper}>
                <Table>
                <TableHead>
                    <TableRow>
                    <TableCell>numberPlate</TableCell>
                    <TableCell>expiry</TableCell>
                    <TableCell>reason</TableCell>
                    <TableCell>category</TableCell>
                    <TableCell>subcategories</TableCell>
                    <TableCell>requesterName</TableCell>
                    <TableCell>requesterEmail</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row) => (
                    <TableRow key={row.numberPlate}>
                        <TableCell component="th" scope="row">
                        {row.numberPlate}
                        </TableCell>
                        <TableCell>{row.expiry}</TableCell>
                        <TableCell>{row.reason}</TableCell>
                        <TableCell>{row.category}</TableCell>
                        <TableCell>{row.subcategories}</TableCell>
                        <TableCell>{row.requesterName}</TableCell>
                        <TableCell>{row.requesterEmail}</TableCell>
                    </TableRow>
                    ))}
                </TableBody>
                </Table>
            </TableContainer>
        </>)
    } else if (error) {
        return (
            <>
                <h4 style={{ marginTop: 0 }}>There was an error reading the data:</h4>
                {error}
            </>
        )
    } else {
        return <p className='pt-8'><strong>{count}</strong> Targeted vehicles to be added or updated.</p>
    }
};