import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';

import { setApproachStatus } from '@actions';
import StatusReportInformation from './StatusReportInformation';
import { ApproachControlButton } from '@components/Button'; 

const ApproachStatusDropdown = ({ id, scid, renderInMap }) => {
  const [approachMenu, setApproachMenu] = useState(null);
  const dispatch = useDispatch();

  const safetyCentre = useSelector(state => state.configuration.safetyCentres?.[scid]);

  const approachMenuClick = (event) => {
    if (event) {
      setApproachMenu(event.currentTarget);
    }
  };

  const approachMenuClose = () => {
    setApproachMenu(null);
  };

  const handleApproachStatusOff = () => {
    if (safetyCentre && id) {
      const aid = id;
      setTimeout(() => {
        dispatch(setApproachStatus(scid, aid, 'OFF'));
        approachMenuClose();
      }, 1000);
    }
  };
  
  const handleApproachStatusOn = (screeningRule) => {
    if (safetyCentre && id) {
      const aid = id;
      setTimeout(() => {
        dispatch(setApproachStatus(scid, aid, 'ON', screeningRule));
        approachMenuClose();
      }, 1000);
    }
  };

  if (!safetyCentre || !id) {
    return null;
  }

  const approach = safetyCentre.approaches.find((a) => a.id === id);
  const status = approach.status;
  const rule = approach.screeningRule;

  const iconText = () => {
    switch (status) {
      case 'OFF':
        return 'Off';
      case 'ON':
        switch (rule) {
          case 'DEFAULT':
            return 'Automatic';
          case 'ALL_HEAVY':
            return 'Heavy Vehicles';
          case 'MAX_1':
            return 'Max 1';
          case 'MAX_2':
            return 'Max 2';
          case 'MAX_3':
            return 'Max 3';
          default:
            return 'Unknown';
        }
      default:
        return 'Unknown';
    }
  };

  const approachStatus = status === 'OFF' ? status : rule;

  return (
    <div className='pl-2'>
      {renderInMap ? (
        <div>
          <p className="mr-4">{approach.name}</p>

          <ApproachControlButton style={{ width: "100%", justifyContent: "space-around" }} approachMenuClick={approachMenuClick} iconText={iconText()}  status={approachStatus} />

          <Popover
            open={Boolean(approachMenu)}
            anchorEl={approachMenu}
            onClose={approachMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            classes={{
              paper: 'py-8 w-88',
            }}
          >
            <DropdownMenuItems status={status} rule={rule} handleApproachStatusOn={handleApproachStatusOn} handleApproachStatusOff={handleApproachStatusOff} />
          </Popover>
        </div>
      ) : (
        <div className="mr-4">
          <div className="flex justify-between items-center pt-4 mb-2">
            <p className="mr-4">{approach.name}</p>
            <StatusReportInformation scid={safetyCentre.id} approachName={approach.name} renderIconOnly />
          </div>
          
          <ApproachControlButton style={{ width: "100%", justifyContent: "space-around" }} approachMenuClick={approachMenuClick} iconText={iconText()}  status={approachStatus} />

          <Popover
            open={Boolean(approachMenu)}
            anchorEl={approachMenu}
            onClose={approachMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            classes={{
              paper: 'py-8 w-88',
            }}
          >
            <DropdownMenuItems status={status} rule={rule} handleApproachStatusOn={handleApproachStatusOn} handleApproachStatusOff={handleApproachStatusOff} />
          </Popover>
        </div>
      )}
    </div>
  );
};

const DropdownMenuItems = ({ status, rule, handleApproachStatusOn, handleApproachStatusOff }) => {
  return (
    <>
      {(status === 'OFF' || (status === 'ON' && rule !== 'ALL_HEAVY')) && 
        <MenuItem onClick={() => handleApproachStatusOn('ALL_HEAVY')} role="button">
          <ListItemText primary="Heavy Vehicles" />
        </MenuItem>
      }
      {(status === 'OFF' || (status === 'ON' && rule !== 'DEFAULT')) && (
        <MenuItem onClick={() => handleApproachStatusOn('DEFAULT')} role="button">
          <ListItemText primary="Automatic" />
        </MenuItem>
      )}
      {(status === 'OFF' || (status === 'ON' && rule !== 'MAX_1')) && (
        <MenuItem onClick={() => handleApproachStatusOn('MAX_1')} role="button">
          <ListItemText primary="Max 1" />
        </MenuItem>
      )}
      {(status === 'OFF' || (status === 'ON' && rule !== 'MAX_2')) && (
        <MenuItem onClick={() => handleApproachStatusOn('MAX_2')} role="button">
          <ListItemText primary="Max 2" />
        </MenuItem>
      )}
       {(status === 'OFF' || (status === 'ON' && rule !== 'MAX_3')) && (
        <MenuItem onClick={() => handleApproachStatusOn('MAX_3')} role="button">
          <ListItemText primary="Max 3" />
        </MenuItem>
      )}
      {status !== 'OFF' &&
        <MenuItem onClick={() => handleApproachStatusOff()} role="button">
          <ListItemText primary="Off" />
        </MenuItem>
      }
    </>
  );
};

export default ApproachStatusDropdown;