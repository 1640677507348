import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { retrieveMeasuredCombination } from '@actions';
import { useEffect } from 'react';

const useMeasuredCombination = id => {
  const mc = useSelector(state => state.measuredCombinations[id], shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(retrieveMeasuredCombination(id));
  }, [id, dispatch]);

  return mc;
};

export default useMeasuredCombination;
