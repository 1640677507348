import { SAFETY_CENTRE_STATUS_REPORT_RECEIVED } from '../action-types';

const initialState = {};

const safetyCentreStatusReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAFETY_CENTRE_STATUS_REPORT_RECEIVED:
      return {
        ...state,
        [action.scid]: action.safetyCentreStatusReport
      };
    default:
      return state;
  }
};

export default safetyCentreStatusReportReducer;