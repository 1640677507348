import React from 'react';
import { styled } from '@mui/material/styles';

import { Page } from '@components/Grid';
import { VmsLinkButton } from '@components/Button';
import Header from '@components/Header';

const LoggedOut = () => {
  const title = 'Vehicle Screening';

  return (
    <Page>
      <Header className="sticky top-0" title={title} onMenuClick={null} showControls={false} />
      <LogoutDiv >
        <VmsLinkButton 
          color="tertiary" 
          text="LOG IN" 
          to={'/'}
          sx={{
            position: 'absolute',
            top: '50%'
          }} 
        />
      </LogoutDiv>
    </Page>

  );
};

const LogoutDiv = styled('div')`
  display: flex;
  justify-content: center;
`;

export default LoggedOut;