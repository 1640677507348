import { useEffect, useState } from 'react';

import { searchMeasuredCombinations } from '../redux/services';

const useAssessmentHistory = (plate, before) => {
  const [measuredCombinations, setMeasuredCombinations] = useState(null);

  // load data
  useEffect(() => {
    const search = async () => {
      // params: scid, approachId, plate, from, to, cursor
      const result = await searchMeasuredCombinations(
        null,
        null,
        plate,
        null,
        before,
        null
      );
      setMeasuredCombinations(result.items);
    };
    search();
  }, [plate, before]);

  return measuredCombinations;
};

export default useAssessmentHistory;
