import { applyMiddleware, combineReducers, createStore } from 'redux';

import { composeWithDevTools } from 'redux-devtools-extension';
import configurationReducer from '../reducers/configuration';
import errorReducer from '../reducers/error';
import measuredCombinationsReducer from '../reducers/measuredCombinations';
import passagesReducer from '../reducers/passages';
import safetyCentreStatusReportReducer from '../reducers/safetyCentreStatusReport';
import targetedVehiclesReducer from '../reducers/targetedVehicles';
import thunk from 'redux-thunk';
import userReducer from '../reducers/user';
import vehicleSearchReducer from '../reducers/vehicleSearch';
import globalParametersReducer from '../reducers/globalParameters';

const store = createStore(
  combineReducers({
    user: userReducer,
    configuration: configurationReducer,
    passages: passagesReducer,
    error: errorReducer,
    targetedVehicles: targetedVehiclesReducer,
    safetyCentreStatusReport: safetyCentreStatusReportReducer,
    vehicleSearchResultItems: vehicleSearchReducer,
    measuredCombinations: measuredCombinationsReducer,
    globalParameters: globalParametersReducer,
  }),
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;
