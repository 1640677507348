import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';

import { getSafetyCentreStatusReport } from '@actions';
import { IconCheck, IconWarning, IconError } from '@icons';

const StatusReportInformation = ({ scid, renderIconOnly, safetyCentre, approachName }) => {
  const dispatch = useDispatch();
  const safetyCentreStatusReport = useSelector(state => state.safetyCentreStatusReport[scid]);

  useEffect(() => {
    const interval = window.setInterval(() => {
      dispatch(getSafetyCentreStatusReport(scid));
    }, 5000);

    return () => {
      window.clearInterval(interval);
    };
  }, [dispatch, scid]);

  const approachStatus = (renderIconOnly && approachName) ? computeApproachStatus(safetyCentreStatusReport, approachName) : computeAllApproachStatus(safetyCentreStatusReport, safetyCentre);

  // render icon in passages page
  if (renderIconOnly && approachStatus && approachName) {
    return (
      <Tooltip 
        title={approachStatus[approachName].message} 
        placement="right-start"
        sx={{
          backgroundColor: (theme) => theme.palette.primary.text,
          color: (theme) => theme.palette.getContrastText(theme.palette.primary.text),
          boxShadow: (theme) => theme.shadows[1],
        }}
      >
        <IconSpan>{approachStatus[approachName].icon}</IconSpan>
      </Tooltip>
    )
  } else if (!renderIconOnly && approachStatus ) {
    // render list of statuses in map window
    return (
      <FlexContainer>
        <Title>Safety Centre Status</Title>
        { Object.keys(approachStatus).map((approach, i) => ( 
          <IconButtonContainer key={`${approach.id}-${i}`}>
            <StatusIcon>{approachStatus[approach].icon}</StatusIcon>
            <StatusInformation>{approachStatus[approach].message}</StatusInformation>
          </IconButtonContainer>
        ))}
      </FlexContainer>
    )
  } else {
    return null;
  }
};

export default StatusReportInformation;

export const computeAllApproachStatus = (safetyCentreStatusReport, safetyCentre) => {
  const systemStatus = {}
  if (safetyCentreStatusReport && safetyCentre) {
    safetyCentre.approaches.forEach(approach => {
      const approachNameSystemLogs = safetyCentreStatusReport.approachNameSystemLogs;
      if (approachNameSystemLogs[approach.name] && approachNameSystemLogs[approach.name].length > 3) {
        systemStatus[approach.name] = {};
        systemStatus[approach.name].icon = <IconError color="error"/>;
        systemStatus[approach.name].message = `${approach.name} - VMS failure - system not signalling`;
      } else if (approachNameSystemLogs[approach.name] && approachNameSystemLogs[approach.name].length > 0) {
        systemStatus[approach.name] = {};
        systemStatus[approach.name].icon = <IconWarning color="warning" />;
        systemStatus[approach.name].message = `${approach.name} - VMS unreliable – may not be signalling`;
      } else {
        systemStatus[approach.name] = {};
        systemStatus[approach.name].icon = <IconCheck color="info"/>;
        systemStatus[approach.name].message = `${approach.name} - No equipment errors at the moment`;
      }
    });
  }

  return Object.keys(systemStatus).length !== 0 ? systemStatus : false;
}

export const computeApproachStatus = (safetyCentreStatusReport, approachName) => {
  const systemStatus = {}
  if (safetyCentreStatusReport && approachName) {
    systemStatus[approachName] = {
      approachName: approachName,
      icon: <IconCheck color="info" />,
      message: "No equipment errors at the moment"
    }

    const approachNameSystemLogs = safetyCentreStatusReport.approachNameSystemLogs;
    if (approachNameSystemLogs[approachName] && approachNameSystemLogs[approachName].length > 3) {
      systemStatus[approachName].icon = <IconError color="error"/>;
      systemStatus[approachName].message = `VMS failure - system not signalling`;
    } else if (approachNameSystemLogs[approachName] && approachNameSystemLogs[approachName].length > 0) {
      systemStatus[approachName].icon = <IconWarning color="warning"/>;
      systemStatus[approachName].message = `VMS unreliable – may not be signalling`;
    }
  }

  return Object.keys(systemStatus).length !== 0 ? systemStatus : false;
}

const FlexContainer = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  background-color: white;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 4px 4px 4px;
`;

const IconSpan = styled("span")`
  cursor: pointer;
  border-radius: 8px;

  &:hover {
    box-shadow: 4px 4px 4px;
  }
`;

const StatusInformation = styled("p")`
    font-size: 12px;
    text-align: left;
`;

const StatusIcon = styled("p")`
    font-size: 12px;
    margin-right: 10px;
`;

const Title = styled("h5")`
  font-size: 16px;
  margin-right: auto;
`;

const IconButtonContainer = styled("div")`
  display: flex;
  align-items: center;
`;