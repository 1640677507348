import { styled } from '@mui/material/styles';

export const Page = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  overflow: hidden;
`;

export const Content = styled("div")(({ theme }) => ({
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
}));

export const FullPageContent = styled("div")(({ theme }) => ({
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  paddingBottom: "5rem",
}));

 export const TopBarContent = styled("div")(({ theme, align }) => ({ 
  display: "flex",
  flexDirection: "row",
  justifyContent: align ? align : "space-between",
  alignItems: "center",
  marginBottom: theme.spacing(2),
  width: "100%",
}));

export const Sidebar = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  margin: 0,
  paddingTop: theme.spacing(5),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingBottom: "100px",
  backgroundColor: theme.palette.secondary.light,
  overflowY: "scroll",
}));

