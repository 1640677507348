import { OktaAuth } from '@okta/okta-auth-js';
export const ENFORCEMENT_OFFICER = 'VSS_ENFORCEMENT_OFFICER';
export const SYSTEM_MAINTAINER = 'VSS_SYSTEM_MAINTAINER';
export const SYSTEM_ADMINISTRATOR = 'VSS_SYSTEM_ADMINISTRATOR';
export const TVL_ADMIN = "VSS_TVL_ADMIN";
export const CVSC_ADMIN = "VSS_CVSC_ADMIN";

// seven hours max session
const MAX_NON_VERIFIED_SESSION_TIME_MS = 7 * 60 * 60 * 1000;

export let auth;

export const initialise = async (config) => {
  
  auth = new OktaAuth({
    issuer: config.issuer,
    clientId: config.clientId,
    redirectUri: window.location.origin + "/implicit/callback",
    pkce: true,
    storageManager: {
      token: {
        storageType: 'sessionStorage',
        storageTypes: []
      }
    }
  });

  try {
    const tokenManager = auth.tokenManager;

    tokenManager.on('expired', function(key) {
      console.log(`${key} has expired`);
    });

    tokenManager.on('renewed', async function(key) {
    console.log(`${key} has been renewed`);

      // check session is not over maximum allowed
      try {
        const session = await auth.session.get();
        const lastVerified = new Date(session.lastPasswordVerification).getTime();
        const now = new Date().getTime();

        if (lastVerified + MAX_NON_VERIFIED_SESSION_TIME_MS < now) {
          console.log(
            'user has not been verified for longer than the maximum allowed period, logging out'
          );
          auth.signOut('/logged-out');
        }
      } catch (e) {
        console.log('unable to retrieve session', e);
        throw e;
      }
    });

    // Triggered when an OAuthError is returned via the API
    tokenManager.on('error', function(err) {
      console.log('TokenManager error:', err.message);
    });

  } catch (error) {
    console.error("Unable to retrieve user");
  }
}