import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { subcategoriesOptions, whitelistSubcategoriesOptions, assessmentTypeOptions, DynamicApproachSelectInput } from './Forms';
import { VmsButton } from './Button';
import { searchMeasuredCombinations } from '@actions';
const dateFormat = "dd/MM/yyyy";

export const SidebarFilterTargetedVehicles = ({ 
  title, 
  defaultValues,
  setFilters
}) => {

    const { handleSubmit, reset, control } = useForm({
        defaultValues: {
            numberPlate: "",
            tslNumber: "",
            expiryFrom: null,
            expiryTo: null,
            subcategories: "",
        },
        mode: 'all',
    });

  return (
    <div >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div className="">
            <Typography variant="h3">{title}</Typography>
            <form sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} onSubmit={handleSubmit((_data) => setFilters(_data))}>
                <FormControl fullWidth >
                    <Controller
                        name="numberPlate"
                        control={control}
                        render={({ field }) => (
                            <TextField {...field} label="Number Plate" 
                                defaultValue={defaultValues?.numberPlate} 
                                inputProps={{ style: { textTransform: "uppercase" } }}
                                sx={{ 
                                    backgroundColor: theme => theme.palette.primary.text, 
                                    borderRadius: 1.5,
                                    mb: 2, 
                                    mt: 2 
                                }} 
                            />
                        )}
                    />
                </FormControl>

                <FormControl fullWidth>
                    <Controller
                        name="tslNumber"
                        control={control}
                        render={({ field }) => (
                            <TextField {...field} label="TSL Number" 
                                defaultValue={defaultValues?.tslNumber} 
                                sx={{ 
                                    backgroundColor: theme => theme.palette.primary.text, 
                                    borderRadius: 1.5,
                                    mb: 2 
                                }} 
                            />
                        )}
                    />
                </FormControl>

                <FormControl fullWidth>
                    <Controller
                        name="expiryFrom"
                        control={control}
                        render={({ field }) => (
                            <DatePicker {...field} label="Expiry From Date" type="date" 
                                format={dateFormat}
                                defaultValue={defaultValues?.from} 
                                sx={{ 
                                    backgroundColor: theme => theme.palette.primary.text, 
                                    borderRadius: 1.5,
                                    mb: 2 
                                }} 
                                slotProps={{
                                    actionBar: {
                                      actions: ['clear']
                                    }
                                }}    
                            />
                        )}
                    />
                </FormControl>

                <FormControl fullWidth>
                    <Controller
                        name="expiryTo"
                        control={control}
                        render={({ field }) => (
                            <DatePicker {...field} label="Expiry To Date" type="date" 
                                format={dateFormat}
                                defaultValue={defaultValues?.to} 
                                sx={{ 
                                    backgroundColor: theme => theme.palette.primary.text,
                                    borderRadius: 1.5, 
                                    mb: 2 
                                }}
                                slotProps={{
                                    actionBar: {
                                      actions: ['clear']
                                    }
                                }}
                            />
                        )}
                    />
                </FormControl>

                <FormControl fullWidth >
                    <InputLabel id="subcategory-select-label">Subcategory</InputLabel>
                    <Controller
                        name="subcategories"
                        control={control}
                        render={({ field }) => (
                            <Select 
                                {...field}
                                label="Subcategory" 
                                labelId="subcategory-select-label"
                                name="subcategories"
                                defaultValue={defaultValues?.subcategories ? defaultValues?.subcategories : ""} 
                                sx={{ 
                                    backgroundColor: theme => theme.palette.primary.text,
                                    borderRadius: 1.5,
                                    mb: 2 
                                }}
                            >
                                <MenuItem value="" disabled>Select a subcategory</MenuItem>
                                {[ { value: "", label: "None" }, ...subcategoriesOptions, ...whitelistSubcategoriesOptions ].map(cat => (
                                    <MenuItem key={cat.value} value={cat.value}>{cat.label}</MenuItem>
                                ))}
                            </Select>
                        )}
                    />
                </FormControl>

                <VmsButton text="Filter" type="submit" fullWidth color="secondary" style={{ marginBottom: "0.5rem" }} />
                <VmsButton text="Reset" type="reset" fullWidth color="secondary" onClick={() => {
                    reset(defaultValues)
                    setFilters(null)
                }} />
            </form>
        </div>
        </LocalizationProvider>
    </div>
  );
};

export const SidebarSearch = ({ 
    title, 
    defaultValues,
    safetyCentres,
    onSubmit,
    safetyCentreOptions
}) => {
    const dispatch = useDispatch();

    const { handleSubmit, reset, control, watch } = useForm({
        defaultValues,
        mode: 'all',
    });
  
    return (
      <div >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
          <div className="pb-2">
              <Typography className="pb-8" variant="h3">{title}</Typography>
                <form sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} onSubmit={handleSubmit((_data) => onSubmit(_data))}>
                    <FormControl fullWidth >
                      <InputLabel id="safetyCentre-select-label">Safety Centre</InputLabel>
                      <Controller
                          name="safetyCentre"
                          control={control}
                          render={({ field }) => (
                              <Select 
                                {...field} 
                                label="Safety Centre" 
                                labelId="safetyCentre-select-label"
                                name="safetyCentre"
                                defaultValue={defaultValues?.safetyCentre} 
                                sx={{ 
                                    backgroundColor: theme => theme.palette.primary.text,
                                    borderRadius: 1.5,
                                    mb: 2,
                                }}
                              >
                                {safetyCentreOptions.map(cat => (
                                    <MenuItem key={cat.value} value={cat.value}>{cat.label}</MenuItem>
                                ))}
                              </Select>
                          )}
                      />
                  </FormControl>

                  <FormControl fullWidth >
                    <InputLabel id="approach-select-label">Approach</InputLabel>
                    <DynamicApproachSelectInput
                        control={control}
                        safetyCentre={watch('safetyCentre')}
                        safetyCentres={safetyCentres}
                        defaultValue={defaultValues?.approachId}
                    />
                  </FormControl>

                  <FormControl fullWidth >
                      <Controller
                          name="plate"
                          control={control}
                          render={({ field }) => (
                              <TextField {...field} label="Number Plate" 
                                defaultValue={defaultValues?.plate} 
                                inputProps={{ style: { textTransform: "uppercase" } }}
                                sx={{ 
                                  backgroundColor: theme => theme.palette.primary.text, 
                                  borderRadius: 1.5,
                                  mb: 2,
                              }} />
                          )}
                      />
                  </FormControl>

                  <FormControl fullWidth>
                      <Controller
                          name="from"
                          control={control}
                          render={({ field }) => (
                              <DatePicker {...field} label="From" type="date" 
                                format={dateFormat}
                                defaultValue={defaultValues?.from} 
                                sx={{ 
                                  backgroundColor: theme => theme.palette.primary.text, 
                                  borderRadius: 1.5,
                                  mb: 2 
                                }}
                                slotProps={{
                                    actionBar: {
                                        actions: ['clear']
                                    }
                                }} 
                              />
                          )}
                      />
                  </FormControl>

                  <FormControl fullWidth>
                      <Controller
                          name="to"
                          control={control}
                          render={({ field }) => (
                              <DatePicker {...field} label="To" type="date" 
                                format={dateFormat}
                                defaultValue={defaultValues?.to} 
                                sx={{ 
                                  backgroundColor: theme => theme.palette.primary.text,
                                  borderRadius: 1.5, 
                                  mb: 2 
                                }}
                                slotProps={{
                                    actionBar: {
                                      actions: ['clear']
                                    }
                                }} 
                              />
                          )}
                      />
                  </FormControl>

                  <FormControl fullWidth >
                    <InputLabel id="assessment-type-select-label">Assessment Type</InputLabel>
                    <Controller
                        name="assessmentType"
                        control={control}
                        render={({ field }) => (
                            <Select 
                                {...field}
                                label="Assessment Type" 
                                labelId="assessment-type-select-label"
                                name="assessmentType"
                                defaultValue={defaultValues?.assessmentType ? defaultValues?.assessmentType : "" } 
                                sx={{ 
                                    backgroundColor: theme => theme.palette.primary.text,
                                    borderRadius: 1.5,
                                    mb: 2 
                                }}
                            >
                                <MenuItem value="" disabled>Select a assessment type</MenuItem>
                                {[ { value: "all", label: "All" }, ...assessmentTypeOptions ].map(cat => (
                                    <MenuItem key={cat.value} value={cat.value}>{cat.label}</MenuItem>
                                ))}
                            </Select>
                        )}
                    />
                </FormControl>
                  
                  <VmsButton text="Filter" type="submit" fullWidth color="secondary" style={{ marginBottom: "0.5rem" }} />
                  <VmsButton text="Reset" type="reset" fullWidth color="secondary" 
                    onClick={() => {
                        dispatch(searchMeasuredCombinations('', "", "", "", '', "", "", true))
                        reset({
                            safetyCentre: '',
                            approachId: '',
                            plate: '',
                            from: null,
                            to: null,
                            assessmentType: ''
                        })
                    }}
                />
              </form>
          </div>
          </LocalizationProvider>
      </div>
    );
};