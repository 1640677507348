import { RECEIVE_MEASURED_COMBINATION } from '../action-types';

const initialState = {};

const measuredCombinationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_MEASURED_COMBINATION:
      return {
        ...state,
        [action.measuredCombination.id]: action.measuredCombination
      };
    default:
      return state;
  }
};

export default measuredCombinationsReducer;
