import React from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import UploadFileIcon from "@mui/icons-material/UploadFile";

export const AllApproachControlButton = ({ approachMenuClick, iconText, status, ...props }) => (
  <VmsIconButton
    onClick={(e) => approachMenuClick(e)}
    icon={ArrowDropDownIcon}
    text={iconText}
    iconPosition="right"
    styleOverrides={{
      color: (theme) => theme.palette.approachDropdown.color[status],
      backgroundColor: (theme) => theme.palette.approachDropdown.background[status],
      borderColor: (theme) => theme.palette.approachDropdown.border[status],
      borderRadius: 1,
      border: 1.5,
      gap: 2,
      width: 120
    }}
    {...props}
  />
);

export const ApproachControlButton = ({ iconText, approachMenuClick, status, ...props }) => (
  <VmsIconButton
    onClick={(e) => approachMenuClick(e)}
    icon={ArrowDropDownIcon}
    text={iconText}
    iconPosition="right"
    size="small"
    styleOverrides={{
      color: (theme) => theme.palette.approachDropdown.color[status],
      backgroundColor: (theme) => theme.palette.approachDropdown.background[status],
      borderColor: (theme) => theme.palette.approachDropdown.border[status],
      borderRadius: 1,
      border: 1.5,
      gap: 2,
      width: 120
    }}
    {...props}
  />
); 

export const VmsIconButton = ({ text, icon, Icon = icon, fill, onClick, className, styleOverrides, iconPosition, color, ...props }) => {
  const theme = useTheme();
  const palette = color ? theme.palette[color] : theme.palette.primary;

  return (
    <IconButton
      className={className}
      onClick={onClick}
      sx={styleOverrides ? styleOverrides : {
        color: palette.text,
        backgroundColor: palette.background,
        borderColor: palette.text,
        borderRadius: 1,
        border: 1.5,
        gap: 1,
      }}
      {...props}
    > 
      { iconPosition === "right" ?
        <>
          <Typography color="inherit">{text}</Typography>
          <Icon />
        </> 
        :
        <> 
          <Icon />
          <Typography color="inherit" >{text}</Typography>
        </>
      }
    </IconButton>
  )
};

export const VmsButton = ({ text, color, styleOverrides, ...props}) => {
  const theme = useTheme();
  const palette = color ? theme.palette[color] : theme.palette.primary;

  return (
    <Button
      sx={styleOverrides ? styleOverrides : {
        color: palette.text,
        backgroundColor: palette.background,
        borderColor: palette.text,
        borderRadius: 1,
        border: 1.5,
        textTransform: "none",
      }}
      {...props}
    >
      <Typography color="inherit">{text}</Typography>
    </Button>
  );
};

export const VmsLinkButton = ({ text, to, color, onClick, styleOverrides, ...props }) => (
  <VmsButton 
    color={color} 
    onClick={onClick} 
    styleOverrides={styleOverrides} 
    href={`${to}`} 
    text={text} 
    {...props} 
  />
);

export const VmsUploadButton = ({ text, color, disabled, parseTargetedVehicleCsv, setParsedUploadData, setError, setCount, ...props}) => (
  <>
    <input
      type="file"
      accept=".csv"
      id="csv-upload"
      style={{ display: 'none' }}
      onChange={(e) => {
        if (!e.target.files) {
          console.log("No files");
          return;
        }
        const file = e.target.files[0];
        parseTargetedVehicleCsv(file, setError, setCount, setParsedUploadData);
      }}
    />
    <label htmlFor="csv-upload">
      <Button disabled={disabled} sx={{ marginLeft: "1rem" }} variant="outlined" component="span" startIcon={<UploadFileIcon />}>
        {text}
      </Button>
    </label>
  </>
);

VmsIconButton.propTypes = {
  color: PropTypes.oneOf([
    'inherit',
    'disabled',
    'primary',
    'secondary',
    'tertiary',
    'action',
    'error',
    'info',
    'success',
    'warning',
  ]),
};

VmsButton.propTypes = {
  color: PropTypes.oneOf([
    'inherit',
    'disabled',
    'primary',
    'secondary',
    'tertiary',
    'action',
    'error',
    'info',
    'success',
    'warning',
  ]),
};