import React from 'react';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';

import { IconUser } from '@icons';
import { Typography } from '@mui/material';

const Navbar = ({ open, onMenuClick, logout }) => {
  const user = useSelector(state => state.user);
  const selected = useSelector(state => state.configuration.selected);

  return (

    <React.Fragment >
      <Drawer
        anchor={"right"}
        open={open}
        onClose={onMenuClick}
        sx={{
          "& .MuiPaper-root": {
            width: "280px",
            padding: 4
          }
        }}
      >
        <Grid container sx={{alignItems: 'center'}}>
          <IconUser sx={{ color: (theme) => theme.palette.primary.main}} size={30} />
          <div className="ml-10">
            <Typography sx={{ fontWeight: "bold" }}>
              {user.name}
            </Typography>
            <Typography variant="p">
              {user.email}
            </Typography>
          </div>
        </Grid>

        <Grid >
          {(user.isCvscAdmin || user.isSystemMaintainer) && (
            <MenuItem className="mt-8">
              <Link onClick={onMenuClick} to={`/safety-centres`}>
                Safety Centres
              </Link>
            </MenuItem>
          )}

          { user.isTvlAdmin && (
            <MenuItem>
              <Link onClick={onMenuClick} to={`/targeted-vehicles`}>
                Targeted Vehicles
              </Link>
            </MenuItem>
          )}

          { (user.isEnforcementOfficer || user.isSystemMaintainer) && (
            <MenuItem>
              <Link onClick={onMenuClick} to={`/assessments`}>
                Search
              </Link>
            </MenuItem>
          )}

          { selected && (
            <MenuItem>
              <Link
                onClick={onMenuClick}
                to={`/safety-centres/${selected}/passages`}
              >
                Passages
              </Link>
            </MenuItem>
          )}

          { user.isSystemAdministrator && (
            <MenuItem>
              <Link
                onClick={onMenuClick}
                to={`/settings`}
              >
                Settings
              </Link>
            </MenuItem>
          )}

          <MenuItem>
            <Link 
              onClick={() => {
                logout()
                onMenuClick()
              }}
              to={'/logged-out'}
            >
              Log out
            </Link>
          </MenuItem>
        </Grid>
        
      </Drawer>
    </React.Fragment>
  );
}

export default Navbar;