import { useNavigate } from 'react-router-dom';

const History = {
  navigate: null,
  push: (page, ...rest) => History.navigate(page, ...rest),
};

const Navigation = () => {
  History.navigate = useNavigate();

  return null;
};

export {History, Navigation};