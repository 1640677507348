import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';

import { toHumanParameterString, fromHumanParameterString } from '@helpers';
import SafetyCentreConfigParameters from './SafetyCentreConfigParameters';
import { updateSafetyCentre, searchSafetyCentres, selectSafetyCentre } from "@actions";

const SafetyCentreConfig = () => {
  const { selected, safetyCentres } = useSelector(state => state.configuration);
  const user = useSelector(state => state.user);
  
  const dispatch = useDispatch();
  const { scid } = useParams();

  useEffect(() => {
    if (!safetyCentres) {
      dispatch(searchSafetyCentres(scid));
    }

    if (!selected) {
      dispatch(selectSafetyCentre(scid));
    }
  });

  if (!user.isCvscAdmin) {
    return <Navigate replace to="/" />;
  };

  const onFinishApproach = (values) => {
    const index = safetyCentre.approaches.findIndex(a => a.id === values.id);
    let newSafetyCentre = structuredClone(safetyCentre);
    newSafetyCentre.approaches[index] = values;
    let payload = { safetyCentreId: newSafetyCentre.id, updateRequest: newSafetyCentre }
    dispatch(updateSafetyCentre(payload));
  };

  const onFinish = (values) => {

    const param = fromHumanParameterString(values.parameter);
    const data = { [param]: values.configValue };
    safetyCentre = { ...safetyCentre, ...data };

    let payload = { safetyCentreId: safetyCentre.id, updateRequest: safetyCentre }
    dispatch(updateSafetyCentre(payload));
  };

  const calculateSafetyCentre = () => {
    if (selected === undefined) {
      return undefined;
    } else if (selected && safetyCentres[selected]) {
      return safetyCentres[selected];
    } else {
      return null;
    }
  };
  let safetyCentre = calculateSafetyCentre();

  let coatData = safetyCentre ? { 
    maxDaysSinceLastRiskModelInspection: safetyCentre.maxDaysSinceLastRiskModelInspection, 
    dailyRiskAssessmentLimit: safetyCentre.dailyRiskAssessmentLimit 
  } : {};

  let cofData = safetyCentre ? { 
    maxDaysSinceLastCofDueExpiryInspection: safetyCentre.maxDaysSinceLastCofDueExpiryInspection, 
    dailyCofDueExpiryAssessmentLimit: safetyCentre.dailyCofDueExpiryAssessmentLimit
  } : {};

  const coatKeys = Object.keys(coatData);
  const cofKeys = Object.keys(cofData);
  
  if (safetyCentre) {
    return (
      <SafetyCentreConfigParameters
        safetyCentre={safetyCentre}
        coatData={coatData}
        cofData={cofData}
        cofKeys={cofKeys}
        coatKeys={coatKeys}
        onFinishApproach={onFinishApproach}
        onFinish={onFinish}
        toHumanParameterString={toHumanParameterString}
      />
    );
  } else {
    return null;
  }
};

export default SafetyCentreConfig;