import { KEY_SELECTED_SAFETY_CENTRE, KEY_CLIENT_ID } from '@actions'

export function retrieveClientIdCookie() {
  return window.localStorage.getItem(KEY_CLIENT_ID);
}

export function retrieveSafetyCentreIdCookie() {
  return window.localStorage.getItem(KEY_SELECTED_SAFETY_CENTRE);
}

export function setSafetyCentreIdCookies(scid) {
  window.localStorage.setItem(KEY_SELECTED_SAFETY_CENTRE, scid);
  window.localStorage.setItem(KEY_CLIENT_ID, scid);
}

export function setSafetyCentreIdCookie(scid) {
  window.localStorage.setItem(KEY_SELECTED_SAFETY_CENTRE, scid);
}

export function getClientId() {
  let clientId = retrieveClientIdCookie();

  if (clientId === null) {
    console.log('client-id is not set');
  } else {
    console.log('client-id is ::', clientId);
  }
  return clientId;
}