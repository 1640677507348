import React, { useEffect, useRef, useState } from 'react';
import { format, parseISO, differenceInSeconds } from 'date-fns';
import { isEmpty } from '@helpers';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';

import { Page, Sidebar } from '@components/Grid';
import { retrieveSafetyCentre, searchPassages, restoreSafetyCentreSelection } from '@actions';
import { filterAssessmentFailures } from '@helpers';
import ApproachStatusDropdown from '@components/ApproachStatusDropdown';
import { VmsIconButton } from '@components/Button';
import { MapModal } from '@components/Modal';
import { IconMap, IconVisibility } from '@icons';
import { AssessmentDetail } from '@components/AssessmentDetail';

const Passages = () => {
  const { scid } = useParams();
  const safetyCentres = useSelector(state => state.configuration.safetyCentres) || {};
  const safetyCentre = safetyCentres[scid];
  const passages = useSelector(state => (safetyCentre && state.passages[safetyCentre.id])) || [];
  const [selectedPassageId, setSelectedPassageId] = useState(null);
  const [mapVisible, setMapVisible] = useState(false);
  let interval = useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    if (scid) {
      dispatch(retrieveSafetyCentre(scid));
    } else {
      dispatch(restoreSafetyCentreSelection());
    }

    // run query to search passages
    dispatch(searchPassages(scid));
    interval.current = window.setInterval(() => dispatch(searchPassages(scid)), 5000);
    setTimeout(retrieveSafetyCentre, 1000);

    return () => {
      interval && window.clearInterval(interval);
    };
  }, [scid, dispatch]);

  useEffect(() => {
    return () => {
      clearInterval(interval);
    };
  }, []);

  const selectPassage = (passage) => {
    setSelectedPassageId(passage.id);
  };

  const deselectPassage = () => {
    setSelectedPassageId(null);
  };

  const toggleMapModal = () => {
    setMapVisible(!mapVisible);
  };

  if (!safetyCentre) {
    return null;
  }

  const selectedPassage = passages && selectedPassageId
    ? passages.find((p) => p.id === selectedPassageId)
    : null;

  const selectedPassageMeasuredCombinationId = selectedPassage
    ? selectedPassage.measuredCombinationId
    : null;

  return (
    <Page >
      <ApproachControlHeader className='flex pb-0' >
        <div className="flex justify-evenly">

          {safetyCentre?.approaches ? (
            safetyCentre.approaches.map((approach) => (
              <ApproachStatusDropdown
                scid={safetyCentre.id}
                id={approach.id}
                key={approach.id}
              />
            ))
          ) : null}

        </div>
        <div className="p-2">
          <VmsIconButton
            icon={IconMap}
            color="secondary"
            text={"View Map"}
            onClick={toggleMapModal}
            size="small"
          />
        </div>
      </ApproachControlHeader>

      <div>
        <Grid container  >
          <Grid item md={3} >
            <Sidebar style={{ height: "84vh" }} className="p-9 m-0 overflow scroll" >
              {passages?.length > 0 ? (
                passages.map((p, i) => {
                  const approach = safetyCentre.approaches.find((a) => a.id === p.approachId);
                  const assessments = p.assessmentInformation.assessments;
                  const assessmentFailures = filterAssessmentFailures(assessments);
                  const isSelected = selectedPassageId === p.id;
                  return (
                    <PassageItem
                      key={p.id}
                      onClick={() => selectPassage(p)}
                      isSelected={isSelected}
                      gutterWidth={"15px"}
                      whiteColor={"#FFF"}
                    >
                      <PassageHeader>
                        <PassageTitle>{p.numberPlate}</PassageTitle>
                        <PassageTime>
                          {p.signalled && !p.weighed && approach && !!p.speed && (
                            <SignalledStatus
                              screened={p.screened}
                              speed={p.speed}
                              visibilityZoneFinish={approach.visibilityZoneFinish}
                              approachName={approach.name}
                            />
                          )}
                          {`  Signalled: ${p.signalled ? format(parseISO(p.signalled), 'HH:mm') : ''}`}
                        </PassageTime>
                      </PassageHeader>
                      <PassageInfo>
                        <p>{approach ? approach.name : "Weigh Bridge"}</p>
                        <PassageTime>{`Weighed: ${p.weighed ? format(parseISO(p.weighed), 'HH:mm') : ''}`}</PassageTime>
                      </PassageInfo>
                      <div style={{ border: "1px solid", borderStyle: "outset" }}></div>
                      <AssessmentFailures>
                        {!isEmpty(assessmentFailures.targeted_fail) && <AssmentFailureAcronym>Targeted</AssmentFailureAcronym>}
                        {!isEmpty(assessmentFailures.measured_fail) && <AssmentFailureAcronym>Measurement</AssmentFailureAcronym>}
                        {!isEmpty(assessmentFailures.id_fail) && <AssmentFailureAcronym>Identification</AssmentFailureAcronym>}
                        {!isEmpty(assessmentFailures.mvr_fail) && <AssmentFailureAcronym>MVR</AssmentFailureAcronym>}
                        {!isEmpty(assessmentFailures.vdam_fail) && isEmpty(assessmentFailures.permit_pass) && (
                          <AssmentFailureAcronym>VDAM</AssmentFailureAcronym>
                        )}
                        {!isEmpty(assessmentFailures.permit_fail) && <AssmentFailureAcronym>Permit</AssmentFailureAcronym>}
                        {!isEmpty(assessmentFailures.ruc_fail) && <AssmentFailureAcronym>RUC</AssmentFailureAcronym>}
                        {!isEmpty(assessmentFailures.risk_fail) && <AssmentFailureAcronym>Ind Risk</AssmentFailureAcronym>}
                        {!isEmpty(assessmentFailures.random_fail) && <AssmentFailureAcronym>Random Inspection</AssmentFailureAcronym>}
                        {!isEmpty(assessmentFailures.cofDueToExpire_fail) && <AssmentFailureAcronym>COF Due</AssmentFailureAcronym>}
                        {!isEmpty(assessmentFailures.latestInspection_fail) && <AssmentFailureAcronym>Exp COF</AssmentFailureAcronym>}
                        {!isEmpty(assessmentFailures.vehicleNotice_fail) && <AssmentFailureAcronym>Vchl Notice</AssmentFailureAcronym>}
                        {!isEmpty(assessmentFailures.unconfirmedBuyer_fail) && <AssmentFailureAcronym>Unc Ownership</AssmentFailureAcronym>}
                        {!isEmpty(assessmentFailures.stolen_fail) && <AssmentFailureAcronym>Stolen</AssmentFailureAcronym>}
                        {!isEmpty(assessmentFailures.vehicleLicence_fail) && <AssmentFailureAcronym>Vchl License</AssmentFailureAcronym>}
                      </AssessmentFailures>
                    </PassageItem>
                  );
                })
              ) : (
                <NoPassages>No Passages</NoPassages>
              )}
            </Sidebar>
            </Grid>

            <Grid item md={9}>
              <div className="flex-1 space-y-15">
                <div className="flex flex-col">
                  {selectedPassageMeasuredCombinationId !== null && (
                    <div className="flex-1">
                      <AssessmentDetail
                        id={selectedPassageMeasuredCombinationId}
                        hide={deselectPassage}
                        renderHeader={false}
                      />
                    </div>
                  )}
                </div>
              </div>
            </Grid>
        </Grid>
      </div>

      <MapModal open={mapVisible} handleClose={toggleMapModal} safetyCentre={safetyCentre} />
    </Page>
  );
};

const ApproachControlHeader = styled("div")`
  dispaly: flex !important;
  height: 90px;
  width: 100%;
  z-index: 0;
  padding: 0 15px 15px 15px;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  box-shadow: 0 0 10px 0;  
`;

const AssmentFailureAcronym = styled("p")`
  padding: 0 5px;
`;

const AssessmentFailures = styled(Container)`
  padding-top: 5px;
  display: flex;
  justify-content: left;
`;

const PassageItem = styled("div")(({ theme, isSelected }) => ({
  cursor: "pointer",
  margin: "0 0 15px 0",
  padding: 9,
  borderRadius: 4,
  radius: '4px',
  border: "solid",
  borderWidth: `${isSelected ? "4px" : "1px"}`,
  borderColor: `${isSelected ? theme.palette.primary.main : "grey"}`,
  height: "auto",
  boxShadow: "4px 4px 4px",
  backgroundColor: `${theme.palette.primary.text}`,
}));


const NoPassages = styled("h5")`
  margin-bottom: 0;
  padding: 10px;
  text-align: center;
`;

const PassageHeader = styled("div")`
  display: flex;
  justify-content: space-between;
`;

const PassageTitle = styled("h4")`
  margin: 0;
`;

const PassageTime = styled("p")`
  display: flex;
  
  > svg {
    margin-right: 7px;
  }
`;

const PassageInfo = styled("div")`
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
`;

export default Passages;

export const SignalledStatus = ({ screened, speed, visibilityZoneFinish, approachName }) => {
  /**
   * Currently TEL, and SH2WB approaches consist of a curve and a roundabout which makes it impossible to
   * predict how long it would take for a vehicle to exit the zone. As a temporary fix, we're going to add 5 seconds to
   * 't' because we can assume that any vehicle in these zones will slow down from the initially detected speed of the vehicle.
   * This prevents an issue where the eye icon (signalled status indicator) prematurely disappearing for these approaches.
   * For more info on future plans: https://waka-kotahi.atlassian.net/browse/WR-1530
   */
  let zoneFinishTimeDelay = 0;
  if (
    approachName &&
    (
      approachName === 'TEL' ||
      approachName === 'SH2 Westbound'
    )
  ) {
    zoneFinishTimeDelay = 5;
  }

  // calculate time to end of zone
  const v = (speed * 1000) / (60 * 60); // speed in m/s
  const t = Math.ceil(visibilityZoneFinish / v) + zoneFinishTimeDelay;

  const now = new Date();
  const diff = differenceInSeconds(now, parseISO(screened));

  if (diff <= t) {
    return (
        <IconVisibility color="secondary"/>
    );
  }

  return null;
};