// errors
export const ERROR_RAISED = 'ERROR_RAISED';
export const ERROR_CLEARED = 'ERROR_CLEARED';

// user
export const USER_RECEIVED = 'USER_RECEIVED';

// safety centres
export const SAFETY_CENTRE_RECEIVED = 'SAFETY_CENTRE_RECEIVED';
export const SAFETY_CENTRE_STATUS_REPORT_RECEIVED = 'SAFETY_CENTRE_STATUS_REPORT_RECEIVED';
export const SAFETY_CENTRES_RECEIVED = 'SAFETY_CENTRES_RECEIVED';
export const SAFETY_CENTRE_HISTORY_RECEIVED = "SAFETY_CENTRE_HISTORY_RECEIVED";
export const SAFETY_CENTRE_SELECTED = 'SAFETY_CENTRE_SELECTED';
export const APPROACH_RECEIVED = 'APPROACH_RECEIVED';

// passages
export const PASSAGES_RECEIVED = 'PASSAGES_RECEIVED';

// vehicle search
export const CLEAR_VEHICLE_SEARCH_RESULTS = 'CLEAR_VEHICLE_SEARCH_RESULTS';
export const EXECUTE_VEHICLE_SEARCH = 'EXECUTE_VEHICLE_SEARCH';
export const RECEIVE_VEHICLE_SEARCH_RESULTS = 'RECEIVE_VEHICLE_SEARCH_RESULTS';

// measured combination
export const RECEIVE_MEASURED_COMBINATION = 'RECEIVE_MEASURED_COMBINATION';

// targeted vehicles
export const RECEIVE_TARGETED_VEHICLES = 'RECEIVE_TARGETED_VEHICLES';
export const DELETE_TARGETED_VEHICLE = 'DELETE_TARGETED_VEHICLE';

// global parameters
export const GLOBAL_PARAMETERS_RECEIVED = 'GLOBAL_PARAMETERS_RECEIVED';

