import React from 'react';
import { useNavigate } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';

import { SafetyCentreParameterConfirmDialog } from '@components/Modal';
import { IconHistory } from '@icons';
import DataGrid from '@components/DataGrid';
import { Page, FullPageContent, TopBarContent } from '@components/Grid';

const subTypeColumns = [
  { field: 'parameter', headerName: 'Parameter', flex: 1, editable: false, sortable: false },
  { field: 'configValue', headerName: 'Configuration Setting', type: 'number', editable: true, sortable: false, align: "center",  headerAlign: "center", flex: 0.4 },
];

const SafetyCentreConfigParameters = ({
  safetyCentre,
  onFinishApproach,
  coatData,
  cofData,
  cofKeys,
  coatKeys,
  onFinish,
  toHumanParameterString
}) => {
  const [promiseArguments, setPromiseArguments] = React.useState(null);
  const [snackbar, setSnackbar] = React.useState(null);
  const navigate = useNavigate();
  const handleCloseSnackbar = () => setSnackbar(null);

  const approachColumns = [
    { field: 'name', headerName: 'Approach Name', flex: 1, editable: false, sortable: false },
    { field: 'visibilityDelay', headerName: 'Visibility Delay (seconds)', type: 'number', editable: true, sortable: false, align: "center",  headerAlign: "center", flex: 0.4 },
    { field: 'visibilityPeriod', headerName: 'Visibility Period (seconds)', type: 'number', editable: true, sortable: false, align: "center",  headerAlign: "center", flex: 0.4,  },
    { field: 'visibilityZoneFinish', headerName: 'Visibility Zone Finish (seconds)', type: 'number', editable: true, sortable: false, align: "center",  headerAlign: "center", flex: 0.4 },
    { field: 'visibilityZoneStart', headerName: 'Visibility Zone Start (seconds)', type: 'number', editable: true, sortable: false, align: "center",  headerAlign: "center", flex: 0.4 },
    {
      field: "history",
      headerName: "History",
      sortable: false,
      flex: 0.2,
      align: "center",
      headerAlign: "center",
      renderCell: (value) => (<IconButton onClick={() => navigate(`/safety-centres/${safetyCentre.id}/${value.row.id}/history`)} color="secondary" ><IconHistory color="secondary" /></IconButton>)
    },
  ];

  const processApproachRowUpdate = (newRow, oldRow) => {
    const mutation = JSON.stringify(newRow) !== JSON.stringify(oldRow);
    try {
      new Promise((resolve, reject) => {
        if (mutation) {
          setPromiseArguments({ 'approach': { resolve, reject, newRow, oldRow }});
        } else {
          return oldRow; // Nothing was changed
        }
      });

      return newRow; 

    } catch (e) {
      console.error("Error updating approach parameters: ", e);
    } 
  };

  const processCoatRowUpdate = (newRow, oldRow) => {
    const mutation = JSON.stringify(newRow) !== JSON.stringify(oldRow);
    try {
      new Promise((resolve, reject) => {
        if (mutation) {
          setPromiseArguments({ 'coat': { resolve, reject, newRow, oldRow }});
        } else {
          return oldRow;  // Nothing was changed
        }
      });

      return newRow; 

    } catch (e) {
      console.error("Error updating COAT parameters: ", e);
    } 
  };

  const processCofRowUpdate = (newRow, oldRow) => {
    const mutation = JSON.stringify(newRow) !== JSON.stringify(oldRow);
    try {
      new Promise((resolve, reject) => {
        if (mutation) {
          setPromiseArguments({ 'cof': { resolve, reject, newRow, oldRow }});
        } else {
          return oldRow;  // Nothing was changed
        }
      });

      return newRow; 

    } catch (e) {
      console.error("Error updating COF parameters: ", e);
    } 
  };

  const processRowError = (e) => {
    console.error("caught error while processing updates, check render method of DataGrid", e)
  }

  const handleYes = async (type) => {
    const { newRow, oldRow, reject, resolve } = promiseArguments[type];

    const resolveFunction = type === 'approach' ? onFinishApproach : onFinish;
    try {
      // Make the HTTP request to save in the backend
      const response = await resolveFunction(newRow);
      setSnackbar({ children: 'Content updated', severity: 'success' });
      resolve(response);
      setPromiseArguments({...promiseArguments, [type]: null});
    } catch (error) {
      setSnackbar({ children: "Error updating row", severity: 'error' });
      reject(oldRow);
      setPromiseArguments({...promiseArguments, [type]: null});
    }
  };

  const resetApproach = (oldRow) => {
    return safetyCentre.approaches.map((approach) => {
      if (approach.id === oldRow.id) {
        return {
          ...approach,
          approach: safetyCentre.defaultApproach,
        };
      } else {
        return approach;
      }
    });
  };

  const handleNo = (type) => {
    const { oldRow, resolve } = promiseArguments[type];
    resolve(oldRow); // Resolve with the old row to not update the internal state
    if (type === 'approach') {
      const newApproaches = resetApproach(oldRow);
      safetyCentre.approaches = newApproaches;
    }
    setPromiseArguments({...promiseArguments, [type]: null});
  };

  const coatRows = coatKeys.map((key) => {
    return {
      'parameter': `${toHumanParameterString(key)}`,
      'configValue': coatData[key]
    }
  });

  const cofRows = cofKeys.map((key) => {
    return {
      'parameter': `${toHumanParameterString(key)}`,
      'configValue': cofData[key]
    }
  })

  return (
    <Page>
      <FullPageContent >
        <TopBarContent>
          <Typography variant="h1" >{safetyCentre ? safetyCentre.name : ""}</Typography>
        </TopBarContent>

        {/* Approach Control Section */}
        <div className="py-2">
          <Typography variant="h3" color="primary" >Approach Timings</Typography>
        </div>
        <div className="flex w-full overflow-y-auto mb-10">
          <div style={{ height: '100%', width: '100%' }}>
            <SafetyCentreParameterConfirmDialog type="approach" promiseArguments={promiseArguments} handleYes={handleYes} handleNo={handleNo} />
            <DataGrid 
              rowId={(row) => row.id} 
              rows={safetyCentre.approaches} 
              columns={approachColumns} 
              processRowUpdate={processApproachRowUpdate} 
              onProcessRowUpdateError={processRowError}
            />
            {!!snackbar && (
              <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={6000}>
                <Alert {...snackbar} onClose={handleCloseSnackbar} />
              </Snackbar>
            )}
          </div>
        </div>

        {/* COAT and COF Section */}
        <Grid container >
          <Grid item md={6} className='pr-4'> 
            <div className="py-2">
              <Typography variant="h3" color="primary" >COAT</Typography>
            </div>
              <div>
                <SafetyCentreParameterConfirmDialog type="coat" promiseArguments={promiseArguments} handleYes={handleYes} handleNo={handleNo} />
                <DataGrid 
                  rowId={(row) => row.parameter} 
                  rows={coatRows} 
                  columns={subTypeColumns} 
                  processRowUpdate={processCoatRowUpdate} 
                  onProcessRowUpdateError={processRowError}
                />
                {!!snackbar && (
                  <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={6000}>
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                  </Snackbar>
                )}
              </div>
          </Grid>

          <Grid item md={6} className='pl-4'> 
            <div className="py-2">
              <Typography variant="h3" color="primary" >COF</Typography>
            </div>
              <div >
                <SafetyCentreParameterConfirmDialog type="cof" promiseArguments={promiseArguments} handleYes={handleYes} handleNo={handleNo} />
                <DataGrid 
                  rowId={(row) => row.parameter} 
                  rows={cofRows} 
                  columns={subTypeColumns} 
                  processRowUpdate={processCofRowUpdate} 
                  onProcessRowUpdateError={processRowError}

                />
                {!!snackbar && (
                  <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={6000}>
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                  </Snackbar>
                )}
              </div>
          </Grid>
        </Grid>
      </FullPageContent>
    </Page>
  );
};

export default SafetyCentreConfigParameters;