import React, { useEffect, useState } from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Security, LoginCallback } from '@okta/okta-react';

import Main from '@containers/Main';
import { auth, initialise } from './auth';
import { RequiredAuth } from './SecureRoute';
import { get } from './http';
import { Navigation, History } from '@components/History';
import FullPageLoader from '@components/Loader';
import LoggedOut from '@containers/LoggedOut';

const App = () => {
  const [configured, setConfigured] = useState(null);

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    History.navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  const configure = async () => {
    try {
      // load the configuration
      const configuration = await get('/api/app/v1/configuration');

      // initialise the auth
      await initialise(configuration.okta);
      console.log('initialised');

      window.CONFIGURATION = configuration.app;
      setConfigured(true);
    } catch (e) {
      console.error('Unable to load configuration from server', e);
      setConfigured(false);
    }
  };

  useEffect(() => {
    configure();
  }, []);

  if (configured === null) {
    return <FullPageLoader open message="Loading configuration..." />;
  }

  if (!auth) {
    return <FullPageLoader open disableSpinner={true} message="Unable to load authorisation configuration. Contact your admins" />;
  }

  return (
    <BrowserRouter >
      <Navigation />
      <Security
        oktaAuth={auth}
        restoreOriginalUri={restoreOriginalUri}
      >
        <Routes>
          <Route path='/implicit/callback' element={<LoginCallback />} />
          <Route exact path="/logged-out" element={<LoggedOut />} /> 
          <Route path="/*" element={<RequiredAuth />}>            
            <Route 
              path='*' 
              element={<Main />}
            />
          </Route>
        </Routes>
      </Security>
    </BrowserRouter>
  );
};

export default App;
