import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { parseISO, format } from 'date-fns';
import { utcToZonedTime  } from "date-fns-tz";
import { orderBy } from '@helpers';
import { Typography, Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import TablePagination from '@mui/material/TablePagination';

import { VmsTab, VmsTabs, TabContentContainer } from "@components/Tabs";
import { SidebarFilterTargetedVehicles } from '@components/SidebarFilter';
import { Content, Page, Sidebar, TopBarContent } from '@components/Grid';
import DataGrid from '@components/DataGrid';
import { VmsButton } from '@components/Button';
import { filterVehicles, toHumanReadableSubcategories, toHumanReadableCategory, parseTargetedVehicleCsv, parseTargetedVehicleData } from '@helpers';
import {
  deleteTargetedVehicles,
  searchTargetedVehicles,
  updateTargetedVehicles,
  saveTargetedVehicle,
  getGlobalParameters
} from '@actions';
import { BulkUploadModal, TargetedVehicleModal, WhitelistVehicleModal, TestVehicleModal } from '@components/Modal';

function convertTypeToReadable(type) {
  switch(type) {
    case "targetedTsl":
      return 'Targeted TSL';
    case "targeted":
      return 'Targeted';
    case "recall":
      return 'Recall';
    default:
      return '';
  }
}

const TAB_TARGETED = 'targeted';
const TAB_RECALL = 'recall';
const TAB_TARGETED_TSL = 'targetedTsl';
const TAB_WHITELIST = 'whitelist';
const TAB_TEST_VEHICLE = 'testVehicle';

const TargetedVehicles = () => {
  const user = useSelector(state => state.user);
  const targetedVehicleDefaultValues = useSelector(state => state.globalParameters.targetedVehicle);
  const tvs = useSelector(state => state.targetedVehicles.vehicles);
  const vehicles = orderBy(tvs || [], ['expiry', 'numberPlate'], ['desc', 'asc'] );

  const [filters, setFilters] = useState(null);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [tab, setTab] = useState(TAB_TARGETED);
  const [targetedModalOpen, toggleTargetedModal] = useState(false);
  const [whitelistModalOpen, toggleWhitelistModal] = useState(false);
  const [testVehicleModalOpen, toggleTestVehicleModal] = useState(false);
  const [targetedEditMode, toggleTargetedEditMode] = useState(false);
  const [targetedType, toggleTargetedType] = useState(false);
  const [whitelistEditMode, toggleWhitelistEditMode] = useState(false);
  const [testVehicleEditMode, toggleTestVehicleEditMode] = useState(false);
  const [uploadModalOpen, toggleUploadModal] = useState(false);
  const [targetedVehicleInitialValues, setTargetedVehicleInitialValues] = useState(null);
  const [whitelistVehicleInitialValues, setWhitelistVehicleInitialValues] = useState(null);
  const [count, setCount] = useState(0);
  const [bulkUploadError, setBulkUploadError] = useState('');
  const [parsedUploadData, setParsedUploadData] = useState(null);
  const [uploadLoading, setUploadLoading] = useState(false);

  const [rowsPerPageTargeted, setRowsPerPageTargeted] = useState(10);
  const [pageTargeted, setPageTargeted] = useState(0);
  const [rowsPerPageTargetedTsl, setRowsPerPageTargetedTsl] = useState(10);
  const [pageTargetedTsl, setPageTargetedTsl] = useState(0);
  const [rowsPerPageWhitelist, setRowsPerPageWhitelist] = useState(10);
  const [pageWhitelist, setPageWhitelist] = useState(0);
  const [rowsPerPageTestVehicle, setRowsPerPageTestVehicle] = useState(10);
  const [pageTestVehicle, setPageTestVehicle] = useState(0);
  
  const dispatch = useDispatch();

  useEffect(() => {
    if (user.isTvlAdmin) {
      dispatch(searchTargetedVehicles());
      dispatch(getGlobalParameters());
    }
  }, [dispatch, user]);

  const deleteSelected = async () => {
    await dispatch(deleteTargetedVehicles(rowSelectionModel));
    setRowSelectionModel([]);
  };

  const handleAddVehicle = async (data) => {
    const tv = parseTargetedVehicleData(data);

    try {
      if (tv.id) {
        await dispatch(updateTargetedVehicles({ vehicles: [ { ...tv } ] }));
      } else {
        await dispatch(saveTargetedVehicle(tv));
      }
    } catch (e) {
      console.log("Uable to save targeted vehicle", e)
    }

    toggleTargetedModal(false);
    toggleWhitelistModal(false);
    toggleTargetedEditMode(false);
    toggleTargetedType("");
    toggleWhitelistEditMode(false);
    toggleTestVehicleEditMode(false);
    toggleTestVehicleModal(false);
  }

  const bulkUploadTargetedVehicles = async () => {
    setUploadLoading(true);

    const targetedVehicles = { vehicles: parsedUploadData };
    try {
      await dispatch(updateTargetedVehicles(targetedVehicles));
    } catch (e) {
      console.log("Error updating targeted vehicles", e)
    } finally {
      setUploadLoading(false);
      setCount(0);
      setBulkUploadError('');
      toggleUploadModal(false);
      setParsedUploadData(null);
    }
  };

  const baseColumns = [
    { field: 'start', headerName: 'Start', type: 'date',  valueFormatter: (value) => format(parseISO(value.value), "dd/MM/yyyy"), sortable: false, align: "center",  headerAlign: "center", flex: 0.2 },
    { field: 'expiry', headerName: 'Expiry', type: 'date',  valueFormatter: (value) => format(utcToZonedTime(parseISO(value.value), "Pacific/Auckland"), "dd/MM/yyyy"), sortable: false, align: "center",  headerAlign: "center", flex: 0.2 },
    { field: 'category', headerName: 'Category', valueGetter: (value) => toHumanReadableCategory(value.row?.category), sortable: false, align: "center",  headerAlign: "center", flex: 0.2 },
    { field: 'subcategories', headerName: 'Subcategories', type: 'array', valueGetter: (value) => toHumanReadableSubcategories(value.row?.subcategories, value.row?.category === "whitelist" ? "whitelist" : value.row?.category === "testvehicle" ? "testvehicle" : null), sortable: false, align: "left",  headerAlign: "left", flex: 0.4 },
    { field: 'reason', headerName: 'Reason', sortable: false, align: "left",  headerAlign: "left", flex: 0.4 },
    { field: 'requesterName', headerName: 'Requester Name', sortable: false, align: "center",  headerAlign: "center", flex: 0.3 },
    { field: 'requesterEmail', headerName: 'Requester Email', sortable: false, align: "center",  headerAlign: "center", flex: 0.3 },
  ];
  
  const targetVehicleColumns = [ 
    { field: 'numberPlate', headerName: 'Plate', flex: 0.2, sortable: false }, 
    ...baseColumns, 
    {
      field: "edit",
      headerName: "Edit",
      sortable: false, 
      align: "center",  
      headerAlign: "center", 
      flex: 0.15,
      renderCell: (value) => (<IconButton onClick={() => {
        setTargetedVehicleInitialValues(value.row);
        toggleTargetedType(value.row.category);
        toggleTargetedEditMode(true);
        toggleTargetedModal(!targetedModalOpen);
      }} color="secondary" ><EditIcon color="primary" /></IconButton>)
    }
  ];

  const targetTslVehicleColumns = [
    { field: 'tslNumber', headerName: 'TSL Number', flex: 0.2, sortable: false },
    ...baseColumns,
    {
      field: "edit",
      headerName: "Edit",
      sortable: false, 
      align: "center",  
      headerAlign: "center", 
      flex: 0.15,
      renderCell: (value) => (<IconButton onClick={() => {
        setTargetedVehicleInitialValues(value.row);
        toggleTargetedType(value.row.category);
        toggleTargetedEditMode(true);
        toggleTargetedModal(!targetedModalOpen);
      }} color="secondary" ><EditIcon color="primary" /></IconButton>)
    },
  ];

  const whitelistVehicleColumns = [
    { field: 'numberPlate', headerName: 'Plate', flex: 0.2, sortable: false },
    ...baseColumns,
    {
      field: "edit",
      headerName: "Edit",
      sortable: false, 
      align: "center",  
      headerAlign: "center", 
      flex: 0.15,
      renderCell: (value) => (<IconButton onClick={() => {
        setWhitelistVehicleInitialValues(value.row);
        toggleWhitelistEditMode(true);
        toggleWhitelistModal(!whitelistModalOpen);
      }} color="secondary" ><EditIcon color="primary" /></IconButton>)
    },
  ];

  const testVehicleColumns = [
    { field: 'numberPlate', headerName: 'Plate', flex: 0.2, sortable: false },
    ...baseColumns
  ];

  const filteredVehicles = filters != null ? filterVehicles(vehicles, filters) : vehicles;
  const targetedVehicles = filteredVehicles.filter((tv) => tv.category === "targeted");
  const recallVehicles = filteredVehicles.filter((tv) => tv.category === "recall");
  const targetedTslVehicles = filteredVehicles.filter((tv) => tv.category === "targetedTsl");
  const whitelistVehicles = filteredVehicles.filter((tv) => tv.category === "whitelist");
  const testVehicles = filteredVehicles.filter((tv) => tv.category === "testvehicle");

  if (!user.isTvlAdmin) {
    return <Navigate replace to="/" />;
  }
  
  return ( 
    <Page >
      <Grid container  >
        <Grid item md={3} >
          <Sidebar >
            <SidebarFilterTargetedVehicles 
              title={"Filter Results"}
              setFilters={setFilters}
            />
          </Sidebar>
        </Grid>

        <Grid item md={9} style={{ overflow: "scroll" }}>
          <Content style={{ maxHeight: "93vh" }}>

            {/* TOPBAR CONTENT */}
            <TopBarContent >
              <div >
                <Typography variant='h1'>Manage Vehicles</Typography>
              </div>
                <div className='flex w-auto justify-between'>
                  <div >
                    <VmsButton text="Add Targeted Vehicle" size="small" color="secondary" onClick={() => {
                      toggleTargetedModal(true)
                      toggleTargetedType("targeted")}}
                    />
                  </div>
                  <div className="ml-4">
                    <VmsButton text="Add Whitelist Vehicle" size="small" color="secondary" onClick={toggleWhitelistModal} />
                  </div>
                  <div className="ml-4">
                    <VmsButton text="Add Test Vehicle" size="small" color="secondary" onClick={toggleTestVehicleModal} />
                  </div>
                  <div className="ml-4">
                    <VmsButton text="Upload CSV" size="small" color="secondary" onClick={toggleUploadModal} />
                  </div>
                  <div className="ml-4">
                    <VmsButton text="Remove Selected" size="small" color="error" disabled={rowSelectionModel.length === 0} onClick={deleteSelected} />
                  </div>
              </div>
            </TopBarContent>
            
            {/* PAGE CONTENT */}
            <div style={{ overflow: "scroll", borderBottom: "1px solid", borderRadius: "4px" }}>
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <VmsTabs
                  value={tab}
                  onChange={(event, newValue) => setTab(newValue)}
                  textColor="primary"
                  indicatorColor="primary"
                >
                  <VmsTab
                    value={TAB_TARGETED}
                    label="Targeted"
                  />
                  <VmsTab
                    value={TAB_RECALL}
                    label="Recall"
                  />
                  <VmsTab
                    value={TAB_TARGETED_TSL}
                    label="Targeted TSL"
                  />
                  <VmsTab
                    value={TAB_WHITELIST}
                    label="Whitelist"
                  />
                  <VmsTab
                    value={TAB_TEST_VEHICLE}
                    label="Test Vehicle"
                  />
                </VmsTabs>
              </Box>

              <div >
                {tab === TAB_TARGETED && (
                  <TabContentContainer>
                    <DataGrid 
                      rowId={(row) => row.id} 
                      rows={targetedVehicles.slice(pageTargeted * rowsPerPageTargeted, pageTargeted * rowsPerPageTargeted + rowsPerPageTargeted)} 
                      columns={targetVehicleColumns} 
                      checkboxSelection
                      disableRowSelectionOnClick
                      onRowSelectionModelChange={(ids) => {
                        setRowSelectionModel(ids);
                      }}
                      rowSelectionModel={rowSelectionModel}
                    />
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 100]}
                      component="div"
                      count={targetedVehicles.length}
                      rowsPerPage={rowsPerPageTargeted}
                      page={pageTargeted}
                      onPageChange={(event, newPage) => {
                        setPageTargeted(newPage)
                      }}
                      onRowsPerPageChange={(event) => {
                        setRowsPerPageTargeted(+event.target.value);
                        setPageTargeted(0);
                      }}
                    />
                  </TabContentContainer>
                )}
                {tab === TAB_RECALL && (
                  <TabContentContainer>
                    <DataGrid 
                      rowId={(row) => row.id} 
                      rows={recallVehicles.slice(pageTargeted * rowsPerPageTargeted, pageTargeted * rowsPerPageTargeted + rowsPerPageTargeted)} 
                      columns={targetVehicleColumns} 
                      checkboxSelection
                      disableRowSelectionOnClick
                      onRowSelectionModelChange={(ids) => {
                        setRowSelectionModel(ids);
                      }}
                      rowSelectionModel={rowSelectionModel}
                    />
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 100]}
                      component="div"
                      count={recallVehicles.length}
                      rowsPerPage={rowsPerPageTargeted}
                      page={pageTargeted}
                      onPageChange={(event, newPage) => {
                        setPageTargeted(newPage)
                      }}
                      onRowsPerPageChange={(event) => {
                        setRowsPerPageTargeted(+event.target.value);
                        setPageTargeted(0);
                      }}
                    />
                  </TabContentContainer>
                )}
                {tab === TAB_TARGETED_TSL && (
                  <TabContentContainer >
                    <DataGrid 
                      rowId={(row) => row.id} 
                      rows={targetedTslVehicles.slice(pageTargetedTsl * rowsPerPageTargetedTsl, pageTargetedTsl * rowsPerPageTargetedTsl + rowsPerPageTargetedTsl)} 
                      columns={targetTslVehicleColumns} 
                      checkboxSelection
                      disableRowSelectionOnClick
                      onRowSelectionModelChange={(ids) => {
                        setRowSelectionModel(ids);
                      }}
                      rowSelectionModel={rowSelectionModel}
                    />
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 100]}
                      component="div"
                      count={targetedTslVehicles.length}
                      rowsPerPage={rowsPerPageTargetedTsl}
                      page={pageTargetedTsl}
                      onPageChange={(event, newPage) => {
                        setPageTargetedTsl(newPage)
                      }}
                      onRowsPerPageChange={(event) => {
                        setRowsPerPageTargetedTsl(+event.target.value);
                        setPageTargetedTsl(0);
                      }}
                    />
                  </TabContentContainer>
                )}
                {tab === TAB_WHITELIST && (
                  <TabContentContainer >
                    <DataGrid 
                      rowId={(row) => row.id} 
                      rows={whitelistVehicles.slice(pageWhitelist * rowsPerPageWhitelist, pageWhitelist * rowsPerPageWhitelist + rowsPerPageWhitelist)} 
                      columns={whitelistVehicleColumns} 
                      checkboxSelection
                      disableRowSelectionOnClick
                      onRowSelectionModelChange={(ids) => {
                        setRowSelectionModel(ids);
                      }}
                      rowSelectionModel={rowSelectionModel}
                    />
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 100]}
                      component="div"
                      count={whitelistVehicles.length}
                      rowsPerPage={rowsPerPageWhitelist}
                      page={pageWhitelist}
                      onPageChange={(event, newPage) => {
                        setPageWhitelist(newPage)
                      }}
                      onRowsPerPageChange={(event) => {
                        setRowsPerPageWhitelist(+event.target.value);
                        setPageWhitelist(0);
                      }}
                    />
                  </TabContentContainer>
                )}
                {tab === TAB_TEST_VEHICLE && (
                  <TabContentContainer >
                    <DataGrid 
                      rowId={(row) => row.id} 
                      rows={testVehicles.slice(pageTestVehicle * rowsPerPageTestVehicle, pageTestVehicle * rowsPerPageTestVehicle + rowsPerPageTestVehicle)} 
                      columns={testVehicleColumns} 
                      checkboxSelection
                      disableRowSelectionOnClick
                      onRowSelectionModelChange={(ids) => {
                        setRowSelectionModel(ids);
                      }}
                      rowSelectionModel={rowSelectionModel}
                    />
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 100]}
                      component="div"
                      count={testVehicles.length}
                      rowsPerPage={rowsPerPageTestVehicle}
                      page={pageTestVehicle}
                      onPageChange={(event, newPage) => {
                        setPageTestVehicle(newPage)
                      }}
                      onRowsPerPageChange={(event) => {
                        setRowsPerPageTestVehicle(+event.target.value);
                        setPageTestVehicle(0);
                      }}
                    />
                  </TabContentContainer>
                )}
              </div>
            </div>
                
          </Content>
        </Grid>
      </Grid>

      {/* MODAL CONTENT */}
      <BulkUploadModal 
        open={uploadModalOpen}
        title="Upload CSV"
        onCancel={() => {
          setUploadLoading(false);
          setCount(0);
          setBulkUploadError(null);
          toggleUploadModal(false);
          setParsedUploadData(null);
        }}
        parseTargetedVehicleCsv={parseTargetedVehicleCsv} 
        setParsedUploadData={setParsedUploadData} 
        parsedUploadData={parsedUploadData}
        setError={setBulkUploadError}
        error={bulkUploadError}
        setCount={setCount}
        count={count}
        bulkUploadTargetedVehicles={bulkUploadTargetedVehicles}
        loading={uploadLoading}
      />

      {/* Manage add and edit of targeted and targeted TSL */}
      <TargetedVehicleModal 
        title={`${targetedEditMode ? "Edit" : "Add"} ${convertTypeToReadable(targetedType)} Vehicle`}
        open={Boolean(targetedModalOpen)}
        targetedVehicleDefaultValues={targetedVehicleDefaultValues}
        disabledPlate={targetedEditMode}
        disabledCategory={targetedEditMode}
        onCancel={() => {
          toggleTargetedEditMode(false);
          toggleTargetedModal(!targetedModalOpen)}
        }
        onAdd={handleAddVehicle}
        initialValues={targetedEditMode ? targetedVehicleInitialValues : {
          requesterName: user.name,
          requesterEmail: user.email,
        }}
      />

      {/* Manage add and edit of whitelist */}
      <WhitelistVehicleModal 
        title={`${whitelistEditMode ? "Edit" : "Add"} Whitelist Vehicle`}
        open={whitelistModalOpen}
        targetedVehicleDefaultValues={targetedVehicleDefaultValues}
        disabledPlate={whitelistEditMode}
        disabledExpiry={false}
        disabledCategory={true}
        disabledSubcategories={true}
        onCancel={() => {
          toggleWhitelistEditMode(false);
          toggleWhitelistModal(!whitelistModalOpen)}
        }
        onAdd={handleAddVehicle}
        initialValues={whitelistEditMode ? whitelistVehicleInitialValues : {
          category: "whitelist",
          subcategories: ["driverChangeOver"],
          start: new Date(),
          requesterName: user.name,
          requesterEmail: user.email,
        }}
      />

      {/* Manage add and edit of test vehicles */}
      <TestVehicleModal 
        title={"Add Test Vehicle"}
        open={testVehicleModalOpen}
        targetedVehicleDefaultValues={targetedVehicleDefaultValues}
        disabledPlate={testVehicleEditMode}
        disabledExpiry={true}
        disabledCategory={true}
        disabledSubcategories={true}
        onCancel={() => {
          toggleTestVehicleEditMode(false);
          toggleTestVehicleModal(!testVehicleModalOpen)}
        }
        onAdd={handleAddVehicle}
        initialValues={{
          category: "testvehicle",
          subcategories: ["test"],
          reason: "Vehicle for testing CVSC equipment",
          start: new Date(),
          requesterName: user.name,
          requesterEmail: user.email,
        }}
      />
         
    </Page> 
  );
};

export default TargetedVehicles;