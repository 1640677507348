import React from "react";

import FullPageLoader from '@components/Loader';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error: error };
  }

  componentDidCatch(error, info) {
    console.error(error, info.componentStack);
  }

  render() {
    if (this.state.hasError) {
      // refer to https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
      return (
        <FullPageLoader open disableSpinner 
          message={
            <>
              <h5 style={{ display: "flex", justifyContent: "center"}}>Something went wrong</h5>
              <p>Error: {this.state.error.message}</p>
            </>
          }
        />
      );
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundary;