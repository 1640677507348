import React from 'react';
import { Tab, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTabs = styled((props) => <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}/>)(
  ({ theme }) => ({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
  },
}));

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    backgroundColor: `${theme.palette.primary.text} !important`,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    borderTopLeftRadius: "0.25rem",
    borderTopRightRadius: "0.25rem",
    color: theme.palette.primary.background,
    border: `1px solid ${theme.palette.primary.background}`,
    '&.Mui-selected': {
      color: '#fff',
      backgroundColor: `${theme.palette.primary.background} !important`,
      borderTopLeftRadius: "0.25rem",
      borderTopRightRadius: "0.25rem",
      border: 1
    },
  }),
);

export const VmsTabs = ({ ...props }) => (
  <StyledTabs {...props} />
);

export const VmsTab = ({ ...props }) => (
  <StyledTab {...props} />
);

export const TabContentContainer = styled("div")(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.background}`,
  padding: 10,
  borderTopRightRadius: 4,
  borderBottomRightRadius: 4,
  borderBottomLeftRadius: 4
}));