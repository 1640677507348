import { GoogleMap, DirectionsRenderer, InfoBox, useJsApiLoader } from '@react-google-maps/api';
import React, { useState, useCallback, useRef, useMemo } from 'react';
import ApproachStatusDropdown from './ApproachStatusDropdown';
import { map_styles, CentreMarker } from './MapStyles';
import StatusReportInformation from './StatusReportInformation';
import PropTypes from 'prop-types';

export default function Map({ safetyCentre }) {
  const [directions, setDirections] = useState(null);
  const [hasDirections, setHasDirections] = useState(false);
  const [bounds, setBounds] = useState(null)
  const mapRef = useRef();

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: window.CONFIGURATION.googleApiKey
  });

  const retrieveDirections = async (safetyCentre) => {
    const DirectionsService = new window.google.maps.DirectionsService();

    let loaded = [];
    return new Promise((resolve) => {
      safetyCentre.approaches.forEach((a) => {
        a.screeningEquipment.forEach((se) => {
          DirectionsService.route(
            {
              origin: se.location,
              destination: safetyCentre.weighStationEquipment.location,
              waypoints: [{ location: a.pullInSigns[0].location }],
              travelMode: "DRIVING",
            },
            (result, status) => {
              if (status === window.google.maps.DirectionsStatus.OK) {
                loaded.push(result);

                if (loaded.length === safetyCentre.approaches.length) {
                  setDirections(loaded);
                  setHasDirections(loaded.length > 0)
                  resolve();
                }
              } else {
                console.error(`error fetching directions ${result}`);
              }
            }
          );
        });
      });
    });
  }

  // eslint-disable-next-line
  const onLoad = useCallback(async (map) => {
    if (!window.google?.maps || !safetyCentre) {
      return;
    };

    await retrieveDirections(safetyCentre);

    // compute bounds of map based on screening equipment locations
    let bounds = new window.google.maps.LatLngBounds();
    safetyCentre.approaches.forEach((a) => {
      a.screeningEquipment.forEach((se) => {
        bounds.extend(new window.google.maps.LatLng(se.location));
      });
      a.pullInSigns.forEach((pi) => {
        bounds.extend(new window.google.maps.LatLng(pi.location));
      });
    });
    bounds.extend(new window.google.maps.LatLng(safetyCentre.location));
    bounds.extend(new window.google.maps.LatLng(safetyCentre.weighStationEquipment.location));

    map.fitBounds(bounds);

    mapRef.current = map;
    setBounds(bounds);
  });

  const getStatusBoxBounds = () => {
    const mapBounds = mapRef.current.getBounds();

    let NECorner;
    if (!mapBounds) {
      setTimeout(() => NECorner = mapBounds?.getNorthEast(), 400);
    } else {
      NECorner = mapBounds.getNorthEast();
    }

    return {
      lat: NECorner.lat(),
      lng: NECorner.lng()
    }
  }

  const MapInstance = useMemo(() => {
    const statusBounds = bounds ?  getStatusBoxBounds() : { lat: null, lng: null };

    if (safetyCentre) {
      // work around for unusual bug where InfoBox disapears from DOM after an approach status change
      bounds && setTimeout(() => mapRef.current.fitBounds(bounds), 600);

      const center = safetyCentre.location;
      return (
        <GoogleMap
          options={{
            styles: map_styles,
            disableDefaultUI: true,
            gestureHandling: 'none',
            keyboardShortcuts: false
          }}
          onLoad={onLoad}
          mapContainerStyle={{ height: `600px`, width: '100%' }}
        >
          <>
            <CentreMarker key={safetyCentre.id} location={center} />

            { /* Render Directions */
              directions && hasDirections &&
                directions.map((d, i) => (
                  <DirectionsRenderer
                    key={`route-${i}`}
                    options={{ 
                      directions: d,
                      suppressMarkers: true,
                      preserveViewport: true,
                      polylineOptions: {
                        strokeColor: 'grey',
                        strokeOpacity: 1,
                        strokeWeight: 6
                      }
                    }}
                  />
                ))
            }

            { /* Create Dropdown InfoBox */
              window.google?.maps?.Size &&
                safetyCentre.approaches.map((approach) => {
                  return (
                    <InfoBox
                      key={approach.id}
                      className="map-control"
                      position={approach.screeningEquipment[0].location}
                      options={{
                        closeBoxURL: '',
                        disableAutoPan: true,
                        pixelOffset: new window.google.maps.Size(-91, -34),
                        boxStyle: {
                          width: '182px',
                          height: '55px',
                          overflow: 'visible',
                          paddingLeft: '-50%',
                          textAlign: 'center'
                        }
                      }}
                    >
                      <ApproachStatusDropdown renderInMap scid={safetyCentre.id} id={approach.id} />
                    </InfoBox>
                  );
                })
              }

              { 
                window.google?.maps?.Size && bounds &&
                <InfoBox
                  position={{ lat : statusBounds.lat, lng: statusBounds.lng }}
                  options={{
                    closeBoxURL: '',
                    disableAutoPan: true,
                    pixelOffset: new window.google.maps.Size(-310, 30),
                    boxStyle: {
                      width: '300px',
                      height: '100px',
                      overflow: 'visible',
                      paddingLeft: '-50%',
                      textAlign: 'center',
                      borderRadius: "50%"
                    }
                  }}
                >
                  <StatusReportInformation renderInMap scid={safetyCentre.id} safetyCentre={safetyCentre} />
                </InfoBox>
              }

          </>
        </GoogleMap>
      )
    }
    // eslint-disable-next-line
  }, [safetyCentre, directions, hasDirections, bounds]);
  
  return isLoaded && safetyCentre ? MapInstance : null;
}

Map.propTypes = {
  safetyCentre: PropTypes.shape({
    id: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
    weighStationEquipment: PropTypes.shape({
      location: PropTypes.object.isRequired,
    }).isRequired,
    approaches: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        screeningEquipment: PropTypes.arrayOf(
          PropTypes.shape({
            location: PropTypes.object.isRequired,
          })
        ).isRequired,
        pullInSigns: PropTypes.arrayOf(
          PropTypes.shape({
            location: PropTypes.object.isRequired,
          })
        ).isRequired,
      })
    ).isRequired,
  }).isRequired,
};