import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Collapse from '@mui/material/Collapse';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';

import { StyledTableCell } from '@components/Table';
import { Page, FullPageContent, TopBarContent } from '@components/Grid';
import { VmsLinkButton } from '@components/Button';
import { searchSafetyCentres, selectSafetyCentre } from '@actions';

const SafetyCentres = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const { selected } = useSelector(state => state.configuration);

  const safetyCentres = useSelector((state) => {
    if (state.configuration.safetyCentres !== undefined) {
      return Object.values(state.configuration.safetyCentres).filter(sc => !!sc.location);
    } else {
      return state.configuration.safetyCentres;
    }
  }, shallowEqual);

  useEffect(() => {
    dispatch(searchSafetyCentres(selected));
  }, [dispatch, selected]);

  if (user.isEnforcementOfficer && !user.isCvscAdmin) {
    return <Navigate replace to="/" />;
  }

  const handleSafteyCenterSelection = (safeyCenterId) => {
    dispatch(selectSafetyCentre(safeyCenterId));
  };

  return (
  <Page>
    <FullPageContent >
      <TopBarContent >
        <Typography variant='h1' align='left'>Safety Centres</Typography>
      </TopBarContent>
      <Paper sx={{ width: '100%', paddingBottom: "100px", overflow: "scroll" }}>
        <TableContainer >
          {
            safetyCentres ?
            <Table stickyHeader aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <StyledTableCell style={{ maxWidth: "20px" }} />
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell align="left">Passages</StyledTableCell>
                  <StyledTableCell align="left">Settings</StyledTableCell>
                  <StyledTableCell align="left">Status</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {safetyCentres.map((sc) => (
                  <Row key={sc.id} sc={sc} handleSafteyCenterSelection={handleSafteyCenterSelection} />
                ))}
              </TableBody>
            </Table> : 
            null
          }
        </TableContainer>
      </Paper>
    </FullPageContent>
  </Page>
  );
};

const Row = ({ sc, handleSafteyCenterSelection }) => {
  const [open, setOpen] = React.useState(false);
  const allApproachesOff = sc.approaches.every((a) => a.status === 'OFF')

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell style={{ maxWidth: "20px" }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {sc.name}
        </TableCell>
        <TableCell align="left"><VmsLinkButton color="secondary" text="Passages" onClick={() => handleSafteyCenterSelection(sc.id)} to={`/safety-centres/${sc.id}/passages`} /></TableCell>
        <TableCell align="left"><VmsLinkButton color="secondary" text="Settings" to={`/safety-centres/${sc.id}/config`} /></TableCell>
        <TableCell align="left">{allApproachesOff ? "OFF" : "ON"}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h3" gutterBottom component="div">
                Approaches
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sc.approaches.map((approach) => (
                    <TableRow key={approach.id}>
                      <TableCell component="th" scope="row">
                        {approach.name}
                      </TableCell>
                      <TableCell>{`${approach.status}${approach.status === "ON" ? " - " + approach.screeningRule : ""}`}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default SafetyCentres;