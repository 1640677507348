import { subcategoriesOptions, categoryOptions, whitelistSubcategoriesOptions, whitelistCategoryOptions } from '@components/Forms';
import { isValid, parse, isBefore, isEqual, isAfter, getYear, subDays, parseISO, startOfDay, addMonths, endOfDay, isWithinInterval } from 'date-fns';
import * as yup from 'yup';
import { testVehicleSubcategoriesOptions, testVehicleCategoryOptions } from './components/Forms';

function filterAssessmentFailures(assessments) {
  return {
    id_fail: assessments.filter((a) => a.type === 'IDENTIFICATION' && a.severity === 'FAILURE'),
    measured_fail: assessments.filter((a) => a.type === 'MEASUREMENT' && a.severity === 'FAILURE'),
    mvr_fail: assessments.filter((a) => a.type === 'MVR' && a.severity === 'FAILURE'),
    vdam_fail: assessments.filter((a) => a.type === 'VDAM' && a.severity === 'FAILURE'),
    permit_pass: assessments.filter((a) => a.type === 'PERMIT' && a.severity === 'INFORMATION'),
    permit_fail: assessments.filter((a) => a.type === 'PERMIT' && a.severity === 'FAILURE'),
    random_fail: assessments.filter((a) => a.type === 'RANDOM' && a.severity === 'FAILURE'),
    targeted_fail: assessments.filter((a) => a.type === 'TARGETED_VEHICLE' && a.severity === 'FAILURE'),
    ruc_fail: assessments.filter((a) => a.type === 'RUC' && a.severity === 'FAILURE'),
    risk_fail: assessments.filter((a) => a.type === 'RISK' && a.severity === 'FAILURE'),
    cofDueToExpire_fail: assessments.filter((a) => a.type === 'COF_DUE_EXPIRY' && a.severity === 'FAILURE'),
    latestInspection_fail: assessments.filter((a) => a.type === 'LATEST_INSPECTION' && a.severity === 'FAILURE'),
    vehicleNotice_fail: assessments.filter((a) => a.type === 'VEHICLE_NOTICE' && a.severity === 'FAILURE'),
    unconfirmedBuyer_fail: assessments.filter((a) => a.type === 'UNCONFIRMED_BUYER' && a.severity === 'WARNING'),
    stolen_fail: assessments.filter((a) => a.type === 'STOLEN_OF_INTEREST' && a.severity === 'FAILURE'),
    vehicleLicence_fail: assessments.filter((a) => a.type === 'VEHICLE_LICENCE' && a.severity === 'FAILURE')
  }
}

const validateRecallModal = values => {
  let errors = {};

  if (!values.numberPlate) {
    errors.numberPlate = `Please enter a ${values.category && values.category === 'targetedTsl' ? "TSL number" : "number plate"}`;
  } else if (values.category !== "targetedTsl" && !/^([a-zA-Z0-9]{1,6})$/gi.test(values.numberPlate)) {
    errors.numberPlate = 'Number Plate must be 1-6 alphanumeric characters';
  } else if (values.category === "targetedTsl" && !/^(\d{7}|\d{5})$/gi.test(values.numberPlate)) {
    errors.numberPlate = "TSL number must be 7 or 5 numbers long";
  }

  if (!!values.expiry) {
    const date = parseISO(values.expiry);

    const today = endOfDay(new Date());
    if (isBefore(date, today)) {
      errors.expiry = 'Expiry date must be in the future.';
    } else if (getYear(date) >= 3000) {
      errors.expiry = 'Expiry date must be in this millenium.';
    }
  }

  if (!values.category) {
    errors.category = "Please select a category";
  }

  if (values.category === 'whitelist' && !values.start) {
    errors.start = "Please add a start date";
  }

  if (values.category === 'whitelist' && values.start) {
    const start = parseISO(values.start);
    const expiry = parseISO(values.expiry);

    if (isBefore(subDays(expiry, 1), start)) {
      errors.start = "Start cannot be after expiry";
    }
  }

  if (!values.subcategories ) {
    errors.subcategories = "Please select a subcategory";
  }

  if (typeof values.subcategories === 'object' && values.subcategories.length <= 0) {
    errors.subcategories = "Please select a subcategory";
  }

  if (!values.reason) {
    errors.reason = "Please enter a reason";
  }

  if (!values.requesterName) {
    errors.requesterName = "Please enter a firstname and lastname"
  }

  if (!values.requesterEmail) {
    errors.requesterEmail = "Please enter a Police or NZTA email";
  } else {
    const email = String(values.requesterEmail).toLowerCase()
    const emailRegex = /^[A-Za-z0-9.]+@(police.govt.nz|nzta.govt.nz)$/;
    const match = new RegExp(emailRegex).test(email);
    
    if (!match) {
      errors.requesterEmail = "Please enter a valid Police or NZTA email"
    }
  }

  return errors;
};

const filterVehicles = (vehicles, filters) => {
  return vehicles.filter((vehicle) => {
    const pass = {};

    if (filters.expiryFrom && filters.expiryTo) {
      const expiry = new Date(vehicle.expiry);
      const expiryFrom = filters.expiryFrom;
      const expiryTo = filters.expiryTo;
      pass.expiryFrom = isAfter(expiry, expiryFrom) || isEqual(expiry, expiryFrom);
      pass.expiryTo = isBefore(expiry, expiryTo) || isEqual(expiry, expiryTo);
    } else if (filters.expiryFrom) {
      const expiry = new Date(vehicle.expiry);
      const expiryFrom = filters.expiryFrom;
      pass.expiryFrom = isAfter(expiry, expiryFrom) || isEqual(expiry, expiryFrom);
    } else if (filters.expiryTo) {
      const expiry = new Date(vehicle.expiry);
      const expiryTo = filters.expiryTo;
      pass.expiryTo = isBefore(expiry, expiryTo) || isEqual(expiry, expiryTo);
    }

    if (filters.numberPlate && vehicle.numberPlate !== null) {
      pass.numberPlate = vehicle.numberPlate.toUpperCase().includes(filters.numberPlate.toUpperCase());
    }

    if (filters.tslNumber && vehicle.tslNumber) {
      pass.tslNumber = vehicle.tslNumber.toUpperCase().includes(filters.tslNumber.toUpperCase());
    }

    if (filters.subcategories && vehicle.subcategories) {
      pass.subcategories = vehicle.subcategories.includes(filters.subcategories);
    }

    return Object.values(pass).every((value) => value === true);
  });
};

const parseTargetedVehicleData = (data) => {
  const targetedVehicle = { 
    ...data, 
    start: data.category === "whitelist" ? new Date(data.start).toISOString() : null,
    expiry: data.category === 'testvehicle' ? new Date().toISOString() : new Date(data.expiry).toISOString(),
    tslNumber: (() => {
      if (data.category === "targetedTsl" && !data.tslNumber) {
        return data.numberPlate;
      } else if (data.category === "targetedTsl" && data.tslNumber) {
        return data.tslNumber;
      } else {
        return null;
      }
    })(),
    numberPlate: data.category === "targetedTsl" ? null : data.numberPlate,
    subcategories: typeof data.subcategories == 'string' ? [data.subcategories] : data.subcategories
  };

  return targetedVehicle;
}

const toHumanParameterString = (str) => {
  switch (str) {
    case "maxDaysSinceLastRiskModelInspection":
      return "Days since last inspection";
    case "dailyRiskAssessmentLimit":
      return "Daily site risk assessment limit";
    case "maxDaysSinceLastCofDueExpiryInspection":
      return "Days since last COF inspection";
    case "dailyCofDueExpiryAssessmentLimit":
      return "Daily COF expiry assessment limit";
    default:
      return "";
  }
}

const fromHumanParameterString = (str) => {
  switch (str) {
    case "Days since last inspection":
      return "maxDaysSinceLastRiskModelInspection";
    case "Daily site risk assessment limit":
      return "dailyRiskAssessmentLimit";
    case "Days since last COF inspection":
      return "maxDaysSinceLastCofDueExpiryInspection";
    case "Daily COF expiry assessment limit":
      return "dailyCofDueExpiryAssessmentLimit";
    default:
      return "";
  }
}

 const toHumanReadableSubcategories = (subcategories, category) => {
  let humanReadableSubcategories = [];
  if (category === "whitelist") {
    humanReadableSubcategories.push(whitelistSubcategoriesOptions[0].label);
  } else if (category === "testvehicle") {
    humanReadableSubcategories.push(testVehicleSubcategoriesOptions[0].label);
  } else {
    subcategories.forEach(subcategories => {
        let foundSubcategories = subcategoriesOptions.find(option => {
        return subcategories === option.value;
      })
      humanReadableSubcategories.push(foundSubcategories.label);
    })
  }
  
  return humanReadableSubcategories.join(", ");
} 

const toHumanReadableCategory = (category) => {
  let option;
  if (category === "whitelist") {
    option = whitelistCategoryOptions[0];
  } else if (category === "testvehicle") {
    option = testVehicleCategoryOptions[0];
  } else {
    option = categoryOptions.find((option) => {
      return category === option.value;
    });
  }

  return option ? option.label : null;
}

const filterSafetyCenterForApproachChanges = (approachHistory) => {
  if (!approachHistory || !Array.isArray(approachHistory)) {
    console.error("Invalid input: approachHistory must be a valid array.");
    return null;
  }
  const response = [];
  for (let i = 0; i < approachHistory.length; i++) {
    const current = approachHistory[i];
    const next = approachHistory[i + 1];

    const ap1 = { visibilityDelay: current.visibilityDelay, visibilityPeriod: current.visibilityPeriod, visibilityZoneFinish: current.visibilityZoneFinish, visibilityZoneStart: current.visibilityZoneStart };
    const ap2 = { visibilityDelay: next?.visibilityDelay, visibilityPeriod: next?.visibilityPeriod, visibilityZoneFinish: next?.visibilityZoneFinish, visibilityZoneStart: next?.visibilityZoneStart };

    const objEqual = JSON.stringify(ap1) === JSON.stringify(ap2);
    if (!objEqual) {
      response.push(current);
    }
  }

  return response;
};

function validateVehicle(vehicle, categories, subcategories) {
  // Check that all required properties are present
  const requiredProperties = [
    'numberPlate',
    'expiry',
    'reason',
    'category',
    'subcategories',
    'requesterName',
    'requesterEmail'
  ];
  for (const property of requiredProperties) {
    if (!vehicle.hasOwnProperty(property)) {
      return { isValid: false, reason: `Missing property: ${property}` };
    }
  }

  // Check that expiry can be parsed into a valid date
  const expiryDate = parse(vehicle.expiry, 'dd/MM/yyyy', startOfDay(new Date()))
  if (!isValid(expiryDate)) {
    return { isValid: false, reason: 'Invalid expiry date' };
  }

  vehicle.expiry = expiryDate.toISOString();

  // Check that expiry is within 6 months of now
  const now = new Date();
  const end = addMonths(new Date(), 6) 
  if (!isWithinInterval(expiryDate, { start: now, end: end })) {
    return { isValid: false, reason: 'Expiry date not within 6 months of now or date is invalid format' };
  }
  
  // Check number plate format
  if ( (vehicle.category === "targeted" && (vehicle.numberPlate == null || vehicle.numberPlate.length > 6)) || (vehicle.category === "recall" && (vehicle.numberPlate == null || vehicle.numberPlate.length > 6)) ) {
    return { isValid: false, reason: 'Invalid number plate' };
  }

  // Check for recall vehicles which are not allowed using bulk upload
  if (vehicle.category === "recall") {
    return { isValid: false, reason: 'Cannot add recall vehicles via bulk upload' };
  }
  
  // Check for tsl number
  if ((vehicle.category === "targetedTsl" && (vehicle.numberPlate == null || !(vehicle.numberPlate.length === 7 || vehicle.numberPlate.length === 5))) ) {
    return { isValid: false, reason: 'Invalid TSL number' };
  }

  // Map tsl number to tslNumber and nullify numberPlate
  if (vehicle.category === "targetedTsl") {
    vehicle.tslNumber = vehicle.numberPlate;
    vehicle.numberPlate = null;
  }

  // Check that category matches a value in the categories list
  if (!categories.includes(vehicle.category)) {
    return { isValid: false, reason: 'Invalid category' };
  }

  // Check that subcategories match a value in the subcategories list
  const subcategoriesToMatch = vehicle.subcategories.split(" ");
  for (const subcategory of subcategoriesToMatch) {
    if (!subcategories.includes(subcategory)) {
      return { isValid: false, reason: 'Invalid subcategory' };
    }
  }

  vehicle.subcategories = [ ...subcategoriesToMatch ]

  // Check that requesterName is not empty
  if (!vehicle.requesterName) {
    return { isValid: false, reason: 'Requester name is empty' };
  }

  // Check that requesterEmail is from a police.govt.nz or nzta.govt.nz email
  const emailRegex = /^[A-Za-z0-9.]+@(police.govt.nz|nzta.govt.nz)$/i;
  if (!emailRegex.test(vehicle.requesterEmail)) {
    return { isValid: false, reason: 'Requester email is not from a police.govt.nz or nzta.govt.nz email' };
  }

  // All checks passed, vehicle is valid
  return { isValid: true };
}

const parseTargetedVehicleCsv = async (file, setError, setCount, setParsedUploadData) => {
  const text = await file.text();

  // check format
  const lines = text.match(/[^\r\n]+/g);
  const headers = lines[0].split(',');
  if (!lines || lines.length < 2 || lines.length > 1001) { // incl header here
    // must have header plus one data
    setError(`file is either empty or greater that 1000 records`);
    return;
  } 

  const vehicles = [];
  for (let i = 1; i < lines.length; i++) {
    const values = lines[i].split(',');
    if (values.length !== headers.length) {
      // missing values
      continue;
    }

    const vehicle = {};
    for (let j = 0; j < headers.length; j++) {
      vehicle[headers[j]] = values[j];
    }
    vehicles.push(vehicle);
  }

  // Now validate the values
  const categories = categoryOptions.map(category => category.value);
  const subcategories = subcategoriesOptions.map(subcategories => subcategories.value);
  const invalidVehicles = [];
  for (const [i, vehicle] of vehicles.entries()) {
    const valid = validateVehicle(vehicle, categories, subcategories);
    if (!valid.isValid) {
      invalidVehicles.push({ ...vehicle, invalidReason: valid.reason, index: i });
    }
  }

  if (invalidVehicles.length > 0) {
    const invalidVehiclesLineList = invalidVehicles.map(line => line.index + 2);
    const invalidVehiclesReasonList = invalidVehicles.map((l) => `Line ${l.index + 2} - ${l.invalidReason} \n`);
    const responseMessage = (
      <>
        <h5>{`Invalid data on line${invalidVehiclesReasonList.length > 0 ? 's' : ""} ${invalidVehiclesLineList.join(", ")}. Ensure all fields have values and match the format in example table`}</h5>
        {invalidVehiclesReasonList.map(line => <p>{line}</p>)}
      </>
    )
    setError(responseMessage);
    return;
  }

  setCount(lines.length - 1);
  if (invalidVehicles.length === 0) {
    setParsedUploadData(vehicles);
  }
}

const toHumanReadableFailureType = (failures) => {
  const map= {
    'RISK': "Risk",
    'RANDOM': "Random Inspection",
    'TARGETED_VEHICLE': "Targeted",
    'MEASUREMENT': "Measurement",
    'IDENTIFICATION': "Identification",
    'RUC': "RUC",
    'MVR': "MVR",
    'VDAM': "VDAM",
    'PERMIT': "Permit",
    'COF_DUE_EXPIRY': "COF Due",
    'LATEST_INSPECTION': "COF",
    'VEHICLE_NOTICE': "Vehicle Notice",
    'UNCONFIRMED_BUYER': "Unc Ownership",
    'STOLEN_OF_INTEREST': "Stolen",
    'VEHICLE_LICENCE': "Vchl License",
  }

  if (failures) {
    let failureString = []
    failures.forEach(failure => {
      failureString.push(map[failure])
    });
    return failureString.join(" ");
  } else {
    return "";
  }
}

const filterTypeDefinition = (type) => {
  switch (type) {
    case 'IDENTIFICATION':
      return 'Identification'
    case 'MEASUREMENT':
      return 'Measurement'
    case 'PERMIT':
      return 'Permit'
    case 'TARGETED_VEHICLE':
      return 'Targeted vehicle'
    case 'RISK':
      return 'Indicative risk'
    case 'RANDOM':
      return 'Random Inspection'
    case 'COF_DUE_EXPIRY':
      return 'COF due to expire'
    case 'LATEST_INSPECTION':
      return 'Expired COF'
    case 'VEHICLE_NOTICE':
      return 'Vehicle notice' 
    case 'UNCONFIRMED_BUYER':
      return 'Unconfirmed ownership'
    case 'STOLEN_OF_INTEREST':
      return 'Stolen Vehicle'
    case 'VEHICLE_LICENCE':
      return 'Vehicle license'
    default:
      return type; 
  }
}

const targetedVehicleSchema = yup.object().shape({
  category: yup.string().required('Please enter a category'),
  numberPlate: yup.string()
    .when(['category', 'tslNumber'], ([category, tslNumber], self, schema) => {
      const id = schema.parent?.id;

      if (category === 'targetedTsl') {
        if (tslNumber && id) { // editing TSL vehicle
          return yup.string().nullable();
        } else { // creating TSL vehicle
          return yup.string().test('tsl-valid', 'TSL must be 5 or 7 digits long', (value) => {
            if (!value) return true;
            if (/^\d+$/.test(value)) {
              if (/^\d{5}$|^\d{7}$/.test(value)) {
                return true;
              }
            } 
          }).required('Please enter a TSL number')
        }
      } else { // creating/editing targeted/recall vehicle
        return yup.string().test('is-plate-valid', 'Number plate must be 6 characters or less', (value) => {
          if (!value) return true;
          if (/^[a-zA-Z0-9]{1,6}$/.test(value)) {
            return true;
          } 
        }).required('Please enter a number plate')
      }
    }),
  subcategories: yup.array().min(1, 'Please select a subcategory').required('Please select a subcategory'),
  reason: yup.string().required('Please enter a reason'),
  requesterName: yup.string().required('Please enter a first name and last name'),
  requesterEmail: yup.string()
    .required('Please enter a Police or NZTA email')
    .matches(/@(nzta\.govt\.nz|police\.govt\.nz)$/, 'Please enter a Police or NZTA email'),
  expiry: yup.date().min(new Date(), "Expiry date must be in the future").required('Please select an expiry date'),
});

const whitelistVehicleSchema = yup.object().shape({
  numberPlate: yup.string().max(6, 'Number plate must be 6 characters or less').required('Please enter a number plate'),
  subcategories: yup.array().required('Subcategory must be "Driver Change Over"'),
  reason: yup.string().required('Please enter a reason'),
  requesterName: yup.string().required('Please enter a first name and last name'),
  requesterEmail: yup.string()
    .required('Please enter a Police or NZTA email')
    .matches(/@(nzta\.govt\.nz|police\.govt\.nz)$/, 'Please enter a Police or NZTA email'),
  expiry: yup.date().min(new Date(), "Expiry date must be in the future").required('Please select an expiry date'),
  start: yup.date().required('Please select an start date'),
  category: yup.string().required('Category must be "whitelist"'),
});

const testVehicleSchema = yup.object().shape({
  numberPlate: yup.string().max(6, 'Number plate must be 6 characters or less').required('Please enter a number plate'),
  subcategories: yup.array().required('Subcategory must be "Test"'),
  reason: yup.string().required('Please enter a reason'),
  requesterName: yup.string().required('Please enter a first name and last name'),
  requesterEmail: yup.string()
    .required('Please enter a Police or NZTA email')
    .matches(/@(nzta\.govt\.nz|police\.govt\.nz)$/, 'Please enter a Police or NZTA email'),
  expiry: yup.date().nullable().required('Please select an expiry date'),
  start: yup.date().nullable().required('Please select an start date'),
  category: yup.string().required('Category must be "Test Vehicle"').matches("testvehicle", 'Please enter a Police or NZTA email'),
});

function isEmpty(value) {
  if (value === null || value === undefined) {
    return true;
  }
  if (typeof value === 'string' || Array.isArray(value)) {
    return value.length === 0;
  }
  if (typeof value === 'object') {
    return Object.keys(value).length === 0;
  }
  return false;
}

function get(object, path, defaultValue) {
  const keys = path.split('.');
  let value = object;
  for (let key of keys) {
    value = value[key];
    if (value === undefined) {
      return defaultValue;
    }
  }
  return value;
}

function orderBy(array, props, orders) {
  if (!array) {
    return array;
  }
  return array.sort((a, b) => {
    for (let i = 0; i < props.length; i++) {
      const prop = props[i];
      const order = orders && orders[i] === 'desc' ? -1 : 1;
      if (a[prop] < b[prop]) {
        return -1 * order;
      } else if (a[prop] > b[prop]) {
        return 1 * order;
      }
    }
    return 0;
  });
}

// Takes an array OR obj and sorts if based on the props provided
function sortBy(array, prop) {
  if (!array || !prop) {
    return array;
  } else if (array && !Array.isArray(array) && typeof array === 'object') {
    const arr = [];
    for (const key in array) {
      if (array.hasOwnProperty(key)) {
        arr.push(array[key]);
      }
    }
    return arr.sort((a, b) => {
      const aProp = a[prop] || '';
      const bProp = b[prop] || '';
      return aProp.localeCompare(bProp);
    });
  } else if (Array.isArray(array)) {
    return array.sort((a, b) => {
      if (a[prop] < b[prop]) {
        return -1;
      } else if (a[prop] > b[prop]) {
        return 1;
      }
      return 0;
    });
  } else {
    return array;
  }
}

function memoize(func) {
  const cache = new Map();
  return function(...args) {
    const key = JSON.stringify(args);
    if (cache.has(key)) {
      return cache.get(key);
    }
    const result = func.apply(this, args);
    cache.set(key, result);
    return result;
  };
}

// Legacy colors for styling legacy CombinationStructure component
const v1Colors = {
  color_dark: '#000',
  color_white: '#FFF',
  color_text: '#555',
  color_text_muted: '#999',
  color_border: '#d9d9da',
  color_link: '#003B5C',
  color_primary: '#AFBD22',
  color_primary_light: '#F7F8E9',
  color_primary_highlight: '#D7DE91',
  color_secondary: '#00456B',
  color_background_highlight: '#F2F4F7',
  color_background_assessed: '#D9DBDE',
  color_background_selected: '#BAD1DF',
  color_error: '#BE3A34',
  color_warning: '#ffc107',
  color_off: '#bd2a22',
  color_v2_primary: '#236fa6',
  color_v2_primary_hover: '#1C5985',
  color_v2_primary_danger: '#BC3832',
  color_v2_primary_danger_hover: '#962D28',
  color_v2_secondary: '#EDF1F8',
  color_v2_secondary_light: '#e5e5e5',
  color_v2_secondary_border_color: '#236FA6',
  color_v2_secondary_hover: '#236FA6',
  color_v2_secondary_danger: '#BC3832',
  color_v2_secondary_danger_hover: '#962D28',
  color_v2_tertiary: '#FFFFFF',
  color_v2_tertiary_border_color: '#236fa6',
  color_v2_tertiary_hover: '#EDF1F8',
  color_v2_tertiary_danger: '#BC3832',
  color_v2_tertiary_danger_hover: '#962D28',
  color_dropdown_toggle_off: '#343a40',
  color_dropdown_toggle_light: '#f0f0f0',
  color_v2_transparent: 'rgba(0,0,0,0)',
  color_v2_white: '#FFF',
  color_v2_green: '#e6f3eb',
  color_v2_green_border: '#06893A',
  color_v2_text_primary: '#FFF',
  color_v2_text_secondary: '#236FA6',
  color_v2_text_tertiary: '#236FA6',
  color_v2_text_danger: '#BC3832'
};

/* eslint-disable import/prefer-default-export */
export {
  filterAssessmentFailures, 
  filterTypeDefinition,
  validateRecallModal, 
  filterVehicles,
  parseTargetedVehicleData,
  parseTargetedVehicleCsv,
  toHumanParameterString,
  fromHumanParameterString,
  toHumanReadableSubcategories,
  toHumanReadableCategory,
  toHumanReadableFailureType,
  filterSafetyCenterForApproachChanges,
  isEmpty,
  get,
  orderBy,
  sortBy,
  memoize,
  targetedVehicleSchema,
  whitelistVehicleSchema,
  testVehicleSchema,
  v1Colors
};
