import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { format, parseISO, subMilliseconds } from 'date-fns';
import { isEmpty } from '@helpers';
import {styled} from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography } from '@mui/material';
import ReactToPrint from 'react-to-print';

import { AssessmentTable } from '@components/AssessmentTable';
import DetailsHeader from '@components/DetailsHeader';
import { CombinationStructure } from '@components/CombinationStructure';
import { filterAssessmentFailures, parseTargetedVehicleData, filterTypeDefinition } from '@helpers';
import useAssessmentHistory from '../hooks/useAssessmentHistory';
import useMeasuredCombination from '../hooks/useMeasuredCombination';
import { RecallConfirmationDialogue } from '@components/RecallConfirmationDialogue';
import { VehicleDetails } from '@components/VehicleDetails';
import { saveTargetedVehicle } from '@actions';
import { IconCheck, IconError, IconWarning, IconPrint } from '@icons';
import { VmsIconButton } from '@components/Button';
import { TargetedVehicleModal } from '@components/Modal';
import { VmsTab, VmsTabs, TabContentContainer } from "@components/Tabs";
import { PermitsList } from '@components/PermitsList';
import { PrintComponent } from '@components/PrintComponents/PrintComponent';

export const AssessmentDetail = ({ id, hide, renderHeader, hideCloseButton }) => {

  const [modalOpen, toggleModal] = useState(false);
  const [reacallDialogueOpen, toggleRecallDialogue] = useState(false);
  const [recallPlate, setRecallPlate] = useState(null);
  const user = useSelector(state => state.user);
  let { mcid } = useParams();
  const measuredCombinationId = !!id ? id : mcid;

  const printRef = React.useRef();
  const dispatch = useDispatch();

  const searchItems = useSelector(state => state.vehicleSearchResultItems);
  const targetedVehicleDefaultValues = useSelector(state => state.globalParameters.targetedVehicle);

  const getSiteApproachName = () => {
    if (searchItems.items) { 
      const approach = searchItems.items.find((a) => a.id === measuredCombinationId);
      if (approach !== undefined) {
        return approach.approachName
      } else {
        return "Unknown";
      }
    };
    return false;
  }
  const siteApproachName = getSiteApproachName();

  const handleModal = () => {
    toggleModal(!modalOpen)
  }

  const handleAddForRecall = async (data) => {
    const targetedVehicle = parseTargetedVehicleData(data);

    try {
      await dispatch(saveTargetedVehicle(targetedVehicle));

      setRecallPlate(targetedVehicle.numberPlate);
      toggleRecallDialogue(true);
      setTimeout(() => {
        setRecallPlate(null);
        toggleRecallDialogue(false);
      }, 5000)
    } catch (e) {
      console.error("Unable to add recall vehicle: ", e)
      setRecallPlate(null);
      toggleRecallDialogue(false);
    } finally {
      handleModal();
    }
  }

  const TAB_ASSESSMENT = 'assessment';
  const TAB_PERMITS = 'permits';
  const TAB_HISTORY = 'history';
  const TAB_DETAILS = 'details';

  const [tab, setTab] = useState(TAB_ASSESSMENT);

  const measuredCombination = useMeasuredCombination(measuredCombinationId);

  if (!measuredCombination) {
    return null;
  }

  const numberPlate = measuredCombination.detectedNumberPlate.accepted;
  const mvrVehiclePrimeMover = measuredCombination?.vehicles[0];
  let recallVehicleInitialValues = {
    numberPlate: numberPlate,
    category: "recall",
    subcategories: [],
    reason: "",
    requesterName: user.name,
    requesterEmail: user.email
  }

  return (
    <div style={{ height: '80vh', overflow: "scroll" }} className="flex flex-col m-8">

      {/* TOPBAR */}
      <div className="flex w-100  items-center">
        <div className="flex-1">
          <h1 className="text-xl">{numberPlate || 'No Plate'}</h1>
        </div>
        <div className='flex justify-between'>
          <div >
            <VmsIconButton
              icon={AddIcon}
              onClick={handleModal}
              size="small"
              text="Mark For Recall"
              color="secondary"
            />
          </div>
          <div className='ml-4'>
            <ReactToPrint
              trigger={() => {
                return <VmsIconButton
                  icon={IconPrint}
                  size="small"
                  text="Print"
                  color="secondary"
                />
              }}
              content={() => printRef.current}
            />
          </div>
          { hideCloseButton === true ? null :
             <div className='ml-4'>
              <VmsIconButton
                onClick={hide}
                size="small"
                icon={CloseIcon}
                text="Close"
                color="secondary"
              />
            </div>
          }
        </div>
      </div>

      <RecallConfirmationDialogue open={reacallDialogueOpen} plate={recallPlate} toggleRecallDialogue={toggleRecallDialogue} />

      <Box sx={{ width: '100%', typography: 'body1' }}>
        <VmsTabs
          value={tab}
          onChange={(event, newValue) => setTab(newValue)}
          textColor="primary"
          indicatorColor="primary"
        >
          <VmsTab
            value={TAB_ASSESSMENT}
            label="Assessments"
          />
          <VmsTab
            value={TAB_DETAILS}
            label="MVR details"
          />
          <VmsTab
            value={TAB_PERMITS}
            label="Permits"
          />
          <VmsTab
            value={TAB_HISTORY}
            label="History"
          />
        </VmsTabs>
      </Box>

        <div style={{ maxHeight: "68vh"}}>
          {tab === TAB_ASSESSMENT && (
            <TabContentContainer>
              { renderHeader && (
                <DetailsHeader
                  capturedDate={measuredCombination.captured}
                  approachName={siteApproachName}
                  measurementSource={measuredCombination.measurementSource}
                />
              )}
              <CombinationStructure
                renderSeperator={renderHeader}
                measuredCombination={measuredCombination}
              />
              <Assessments measuredCombination={measuredCombination} />
            </TabContentContainer>
          )}
          {tab === TAB_DETAILS && (
            <TabContentContainer className="pt-6">
              {mvrVehiclePrimeMover ? (
                <>
                  <VehicleDetails mvrVehicle={mvrVehiclePrimeMover} />
                  <Typography className="pt-4">
                    <b>MVR data last updated:</b>{' '}
                    {mvrVehiclePrimeMover.dataAsAt
                      ? format(
                          new Date(mvrVehiclePrimeMover.dataAsAt),
                          'd/M/y h:mm a'
                        )
                      : 'N/A'}
                  </Typography>
                </>
              ) : (
                <Typography>No current MVR details were found.</Typography>
              )}
            </TabContentContainer>
          )}
          {tab === TAB_PERMITS && (
            <TabContentContainer className={`pt-6 overflow-scroll h-full pb-${!renderHeader ? 'pt-0' : ''}`}>
              { renderHeader && (
                <DetailsHeader
                  capturedDate={measuredCombination.captured}
                  approachName={siteApproachName}
                  measurementSource={measuredCombination.measurementSource}
                />
              )}
              <PermitsList measuredCombination={measuredCombination} />
            </TabContentContainer>
          )}
          {tab === TAB_HISTORY && (
            <TabContentContainer className={`p-4 overflow-scroll h-full pb-8 ${!renderHeader ? 'pt-4' : ''}`}>
              {numberPlate ? (
                <>
                  { renderHeader && (
                    <DetailsHeader
                      capturedDate={measuredCombination.captured}
                      approachName={siteApproachName}
                      measurementSource={measuredCombination.measurementSource}
                    />
                  )}
                  <AssessmentHistory
                    plate={numberPlate}
                    before={measuredCombination.captured}
                    user={user}
                  />
                </>
              ) : (
                <Typography>
                  No previous passages found for this vehicle (no plate to match).
                </Typography>
              )}
            </TabContentContainer>
          )}
        </div>

      {/* INJECT PRINT TEMPLATE INTO DOM WITH THEME STYLES */}
      { numberPlate && measuredCombination && 
        <div style={{ display: 'none' }} >
          <PrintComponent 
            ref={el => (printRef.current = el)} 
            numberPlate={numberPlate} 
            mc={measuredCombination} 
          />  
        </div>
      }
      
      {/* INJECT TV MODAL */}
      <TargetedVehicleModal 
        title="Mark for recall" 
        formType="recall"
        open={modalOpen}
        disabledPlate={true}
        disabledCategory={true}
        disabledExpiry={true}
        onCancel={handleModal}
        onAdd={handleAddForRecall}
        initialValues={recallVehicleInitialValues}
        targetedVehicleDefaultValues={targetedVehicleDefaultValues}
      />

    </div>
  );
};

export const Assessments = ({ measuredCombination, print = false }) => {
  // evaluate assessments
  const none = isEmpty(measuredCombination.assessmentInformation.assessments);
  if (none) {
    return null;
  }
  // exclude COAT assessment for printout
  const assessments = print ? measuredCombination.assessmentInformation.assessments.filter((a) => a.type !== 'RISK') : measuredCombination.assessmentInformation.assessments;

  const assessmentFailures = filterAssessmentFailures(assessments);

  return (
    <UnstyledList>
      {!isEmpty(assessmentFailures.targeted_fail) && ( <AssessmentMessages fail list={assessmentFailures.targeted_fail} /> )}
      {!isEmpty(assessmentFailures.permit_fail) && <AssessmentMessages fail list={assessmentFailures.permit_fail} />}
      {!isEmpty(assessmentFailures.permit_pass) && <AssessmentMessages pass list={assessmentFailures.permit_pass} />}
      {!isEmpty(assessmentFailures.measured_fail) && ( <AssessmentMessages fail list={assessmentFailures.measured_fail} /> )}
      {!isEmpty(assessmentFailures.id_fail) && <AssessmentMessages fail list={assessmentFailures.id_fail} />}
      {!isEmpty(assessmentFailures.mvr_fail) && <AssessmentMessages fail list={assessmentFailures.mvr_fail} />}
      {!isEmpty(assessmentFailures.vdam_fail) && <AssessmentMessages fail list={assessmentFailures.vdam_fail} />}
      {!isEmpty(assessmentFailures.ruc_fail) && <AssessmentMessages fail list={assessmentFailures.ruc_fail} />}
      {!isEmpty(assessmentFailures.risk_fail) && <AssessmentMessages fail list={assessmentFailures.risk_fail} />}
      {!isEmpty(assessmentFailures.random_fail) && <AssessmentMessages fail list={assessmentFailures.random_fail} />}
      {!isEmpty(assessmentFailures.cofDueToExpire_fail) && <AssessmentMessages fail list={assessmentFailures.cofDueToExpire_fail} />}
      {!isEmpty(assessmentFailures.latestInspection_fail) && <AssessmentMessages fail list={assessmentFailures.latestInspection_fail} />}
      {!isEmpty(assessmentFailures.vehicleNotice_fail) && <AssessmentMessages fail list={assessmentFailures.vehicleNotice_fail} />}
      {!isEmpty(assessmentFailures.unconfirmedBuyer_fail) && <AssessmentMessages warn list={assessmentFailures.unconfirmedBuyer_fail} />}
      {!isEmpty(assessmentFailures.stolen_fail) && <AssessmentMessages fail list={assessmentFailures.stolen_fail} />}
      {!isEmpty(assessmentFailures.vehicleLicence_fail) && <AssessmentMessages fail list={assessmentFailures.vehicleLicence_fail} />}
    </UnstyledList>
  );
};

const AssessmentMessages = ({ list, pass, fail, warn }) => {
  return list.map((a, i) => (
    <li key={`${i}_${a.message}`}>
      <span style={{ fontSize: '120%' }}>
        <InlineIcon style={{ fontSize: '100%' }}>
          {fail && <IconError color="error" />}
          {warn && <IconWarning color="warning" />}
          {pass && <IconCheck color="success"/>}
        </InlineIcon>
      </span>
      <span style={{ fontWeight: "bold" }}>
        {filterTypeDefinition(a.type)}
      </span>
      {` - ${a.message}`}
    </li>
  ));
};

const AssessmentHistory = ({ plate, before, user }) => {
  // exclude before
  const beforeBefore = subMilliseconds(parseISO(before), 1).toISOString();

  const measuredCombinations = useAssessmentHistory(plate, beforeBefore);

  if (!measuredCombinations) {
    return null;
  } else if (measuredCombinations.length === 0) {
    return <div>No previous passages found for this vehicle.</div>;
  } else {
    return <AssessmentTable measuredCombinations={measuredCombinations} user={user} showLinkToDetails={true} />;
  }
};

const InlineIcon = styled("span")`
  display: inline-block;
  font-size: 70%;
  vertical-align: middle;
  margin-right: 0.5em;
`;

export const UnstyledList = styled("ul")`
  margin-top: 2rem;
  
  > li {
    list-style-type: none;
  }
`;
