import React, { useState, useEffect, forwardRef } from 'react';
import { format, parseISO, subMilliseconds } from 'date-fns';

import { AssessmentTable } from '@components/AssessmentTable';
import { Assessments } from '@components/AssessmentDetail';
import * as api from '../../redux/services';
import { IconWarning } from '@icons';
import { PermitsList } from '@components/PermitsList';
import { CombinationStructure } from '@components/CombinationStructure';
import { VehicleDetails } from '@components/VehicleDetails';

export const PrintComponent = forwardRef((props, ref) => {
  const [allMeasuredCombinations, setAllMeasuredCombinations] = useState(null);
  const { numberPlate, mc } = props;
  const safetyCentre = mc.measurementSource.safetyCentre

  useEffect(() => {
    async function fetchAllMeasuredCombinations() {
      const data = await api.searchMeasuredCombinations(null, null, numberPlate, null, null, null);
      setAllMeasuredCombinations(data);
    }

    fetchAllMeasuredCombinations();
  }, [numberPlate]);
    
  // gather all data 
  const captured = format(parseISO(mc.captured), 'dd/MM/yyyy HH:mm a');
  const before = subMilliseconds(parseISO(mc.captured), 1);
  const mvrVehiclePrimeMover = mc?.vehicles[0];
  
  // get all measured combinations for the specified number plate and do filtering afterwards
  const approach = allMeasuredCombinations?.items.find((a) => a.id === mc.id);
  const approachName =  approach ? approach.approachName : "Unknown";
  const historyRecords = allMeasuredCombinations?.items.filter((a) => subMilliseconds(parseISO(a.captured), 1) < before);
  const topFifteenRecords = historyRecords?.slice(0, 15);

  // show warning if there are more than 15 history records
  const mvrDataAsYetFormated = mvrVehiclePrimeMover?.dataAsAt ? format(new Date(mvrVehiclePrimeMover.dataAsAt), 'd/M/y h:mm a') : 'N/A';
  const mvrDataLastUpdated = mvrVehiclePrimeMover ? mvrDataAsYetFormated : 'N/A';

  return (
    <div className="p-8 overflow-scroll" ref={ref}>
      <div className="flex justify-between items-center border border-solid">
        <h2>{numberPlate}</h2>
        <span class="adjust-font"><strong>Event date/time:</strong>&nbsp;{captured}</span>
      </div>

      <div className="flex justify-between items-center border-b-2 border-solid">
        <h4>{safetyCentre}&nbsp;&nbsp;&#8226;&nbsp;&nbsp;{approachName}</h4>
        <div>
          <div><strong>MVR data last updated:</strong> {mvrDataLastUpdated}</div>
          <div className='flex items-center'>
            <IconWarning className="pr-4" color="warning"/> Note: Data from MVR could be 24hrs out of date
          </div>
        </div>
      </div>

      <div className='mb-12 mt-4'>
        <h3>Weigh Data</h3>
        <CombinationStructure measuredCombination={mc} print={true} />
      </div>

      <div className='mb-8'>
        <h3>Assessments</h3>
        <Assessments measuredCombination={mc} print={true} />
      </div>

      <div className='mb-8'>
        <h3>Permits</h3>
        <PermitsList measuredCombination={mc} />
      </div>

      <div className='mb-8'>
        <div className='flex'>
          <h3>History</h3>
          { historyRecords?.length > 15 ? 
            <div className='flex items-center'>
              <IconWarning className="pr-4" color="warning"/> Note: Only the most recent 15 records have been printed.
            </div> : 
            ''
          }
        </div>
        <AssessmentTable renderToPrint measuredCombinations={topFifteenRecords} />
      </div>

      <div>
        <h3>MVR Details</h3>
        <VehicleDetails mvrVehicle={mvrVehiclePrimeMover} />
      </div>
    </div>
  );
});