import React, { useEffect }from 'react';
import { endOfDay, parseISO, startOfDay } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import { Typography, Box } from '@mui/material';

import { searchMeasuredCombinations, searchSafetyCentres } from '@actions';
import { sortBy } from '@helpers';
import { SidebarSearch } from '@components/SidebarFilter';
import { AssessmentTable } from '@components/AssessmentTable';
import { Sidebar, TopBarContent, Content, Page } from '@components/Grid';

const Search = () => {
  const user = useSelector(state => state.user);
  const selected = useSelector(state => state.configuration?.selected);
  const stateQuery = useSelector(state => state.vehicleSearchResultItems.query);
  const query = !stateQuery ? { scid: selected } : stateQuery;
  const returnedVehicleSearchResultItems = useSelector(state => state.vehicleSearchResultItems.items);
  const cursor = useSelector(state => state.vehicleSearchResultItems.cursor || '');
  const sorted = useSelector(state => sortBy(state.configuration?.safetyCentres, s => s.name));
  const currentSafetycenter = sorted?.find((sc) => sc.id === query.scid);
  const safetyCentres = useSelector(state => state.configuration?.safetyCentres);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(searchSafetyCentres(selected));
  }, [dispatch, selected]);

  const search = (values) => {
    const from = Boolean(values.from) && values.from !== 'Invalid Date' && !isNaN(new Date(values.from))
      ? startOfDay(values.from).toISOString()
      : '';
    const to = Boolean(values.to) && values.to !== 'Invalid Date' && !isNaN(new Date(values.to))
      ? endOfDay(values.to).toISOString()
      : '';

    const approachId = values.safetyCentre === "All" || values.safetyCentre === "" ? "" : values.approachId;

    dispatch(searchMeasuredCombinations(values.safetyCentre, approachId, values.plate, from, to, values.assessmentType === "all" ? null : values.assessmentType, "", true));
  };

  const loadMoreData = () => {
    dispatch(searchMeasuredCombinations(
      query.scid,
      query.approachId,
      query.plate,
      query.from,
      query.to,
      query.assessmentType,
      cursor,
    ));
  };

  let options = [];
  sorted?.forEach((sc) => {
    if (sc.id !== query.scid) {
      options.push({
        value: sc.id,
        label: sc.name
      });
    }
  });

  options.unshift({
    value: "All",
    label: "All"
  });

  currentSafetycenter && options.unshift({
    value: currentSafetycenter.id,
    label: currentSafetycenter.name
  });

  const calculateInitialDateValue = (prop) => {
    if (query[prop] === '') {
      return null;
    } else if (typeof query[prop] === 'string') {
      return parseISO(query[prop]);
    } else if (typeof query[prop] === 'object' && !isNaN(new Date(query[prop]))) {
      return query[prop];
    } else {
      return null;
    }
  }
  const initialValues = {
    plate: query.plate ? query.plate : '',
    from: calculateInitialDateValue('from'),
    to: calculateInitialDateValue('to'),
    safetyCentre: query.scid ? query.scid : '',
    assessmentType: query.assessmentType ? query.assessmentType : "",
    approachId: query.approachId ? query.approachId : ""
  };

  return (
    <Page >
      <Grid container >
        <Grid item md={3}> 

          {/* SIDEBAR CONTENT */}
          <Sidebar >
            <SidebarSearch
              title="Search"
              onSubmit={search}
              safetyCentreOptions={options}
              safetyCentres={safetyCentres}
              defaultValues={initialValues}
            />
          </Sidebar>
        </Grid>

        <Grid item md={9} style={{ overflow: "scroll" }}>
          <Content style={{ maxHeight: "93vh" }}>
            <TopBarContent className="flex w-100  items-center justify-between">
              <div >
                <Typography variant='h1'>{`Search Results (${returnedVehicleSearchResultItems ? returnedVehicleSearchResultItems.length: "0"})`}</Typography>
              </div>
              
            </TopBarContent>

            {/* PAGE CONTENT */}
            <div style={{ overflow: "scroll", border: "1px solid", borderRadius: "4px"  }}>
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <AssessmentTable
                  measuredCombinations={returnedVehicleSearchResultItems}
                  cursor={cursor}
                  showLinkToDetails
                  loadMoreData={loadMoreData}
                  user={user}
                />
              </Box>
            </div>
          </Content>
        </Grid>
      </Grid>
    </Page>
  );
}

export default Search;