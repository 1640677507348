import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import DataGrid from '@components/DataGrid';
import { SettingsConfirmDialog } from '@components/Modal';
import { Page, FullPageContent, TopBarContent } from '@components/Grid';
import { getGlobalParameters, updateGlobalParameter } from '@actions';
import { GLOBAL_PARAMETERS_RECEIVED } from '../redux/action-types';

const Settings = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const targetedVehicleDefaultValues = useSelector(state => state.globalParameters.targetedVehicle);
  const [promiseArguments, setPromiseArguments] = React.useState(null);

  useEffect(() => {
    dispatch(getGlobalParameters());
  }, [dispatch]);

  if (!user.isSystemAdministrator) {
    return <Navigate replace to="/" />;
  }

  const processRowUpdate = (newRow, oldRow) => {
    const mutation = JSON.stringify(newRow) !== JSON.stringify(oldRow);
    try {
      new Promise((resolve, reject) => {
        if (mutation) {
          setPromiseArguments({ resolve, reject, newRow, oldRow });
        } else {
          return oldRow; // Nothing was changed
        }
      });

      return newRow; 

    } catch (e) {
      console.error("Error updating approach parameters: ", e);
    } 
  };

  const columns = [
    { field: 'key', headerName: 'Category', flex: 1, editable: false, sortable: false },
    { field: 'value', headerName: 'Value', type: 'number', editable: true, sortable: false, align: "center",  headerAlign: "center", flex: 0.4 },
    { 
      field: 'timeUnit', 
      headerName: 'Unit', 
      editable: true, 
      sortable: false, 
      align: "center",  
      headerAlign: "center", 
      flex: 0.4,
      renderCell: (params) => (
        <Select
          value={params.value}
          sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
          onChange={(event) => {
            const newTimeUnit = event.target.value;
            const newRow = { ...params.row, timeUnit: newTimeUnit };
            processRowUpdate(newRow, params.row);
          }}
        >
          <MenuItem key="HOURS" value="HOURS">Hours</MenuItem>
          <MenuItem key="DAYS" value="DAYS">Days</MenuItem>
          <MenuItem key="MONTHS" value="MONTHS">Months</MenuItem>
          <MenuItem key="YEARS" value="YEARS">Years</MenuItem>
        </Select>
      ),
    }
  ];

  const processRowError = (e) => {
    console.error("caught error while processing updates, check render method of DataGrid", e)
  }

  const handleYes = async () => {
    const { newRow, oldRow, reject, resolve } = promiseArguments;

    try {
      // Make the HTTP request to save in the backend
      const response = await dispatch(updateGlobalParameter(newRow));
      resolve(response);
      setPromiseArguments(null);
    } catch (error) {
      reject(oldRow);
      setPromiseArguments(null);
    }
  };

  const handleNo = () => {
    const { oldRow, resolve } = promiseArguments;
    resolve(oldRow); // Resolve with the old row to not update the internal state
    const oldRows = () => {
      return targetedVehicleDefaultValues.map((item) => {
        if (item.key === oldRow.key) {
          return oldRow;
        } else {
          return item;
        }
      });
    }
    dispatch({
      type: GLOBAL_PARAMETERS_RECEIVED,
      globalParameters: oldRows(),
    });
    setPromiseArguments(null);
  };
 
  return (
    <Page>
      <FullPageContent >
        <TopBarContent >
          <Typography variant='h1' align='left'>Global Settings</Typography>
        </TopBarContent>

        <div className="py-2">
          <Typography variant="h3" color="primary" >Targeted Vehicle Form Default Expiry Date Configuration</Typography>
        </div>

        <Paper sx={{ width: '100%', overflow: "scroll" }}>

          <SettingsConfirmDialog promiseArguments={promiseArguments} handleYes={handleYes} handleNo={handleNo} />

          <DataGrid 
            rowId={(row) => row.key} 
            rows={targetedVehicleDefaultValues} 
            columns={columns} 
            processRowUpdate={processRowUpdate} 
            onProcessRowUpdateError={processRowError}
          />

        </Paper>
      </FullPageContent>
    </Page>
  );
};

export default Settings;