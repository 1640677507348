import {
  SAFETY_CENTRES_RECEIVED,
  SAFETY_CENTRE_RECEIVED,
  SAFETY_CENTRE_HISTORY_RECEIVED,
  SAFETY_CENTRE_SELECTED,
  APPROACH_RECEIVED
} from '../action-types';

const initialState = {
  safetyCentres: undefined,
  selected: undefined
};

const configurationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAFETY_CENTRES_RECEIVED:
      return {
        ...state,
        safetyCentres: action.safetyCentres.reduce((acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        }, {})
      };
    case SAFETY_CENTRE_RECEIVED:
      return {
        ...state,
        safetyCentres: {
          ...state.safetyCentres,
          [action.safetyCentre.id]: action.safetyCentre
        }
      };
    case APPROACH_RECEIVED:
      // update approach within sc
      const safetyCentre = state.safetyCentres[action.scid];
      const approaches = safetyCentre.approaches.map(a => {
        if (a.id === action.approach.id) {
          return action.approach;
        } else {
          return a;
        }
      });

      return {
        ...state,
        safetyCentres: {
          ...state.safetyCentres,
          [action.scid]: {
            ...safetyCentre,
            approaches
          }
        }
      };
    case SAFETY_CENTRE_SELECTED:
      return {
        ...state,
        selected: action.selected
      };
    
    case SAFETY_CENTRE_HISTORY_RECEIVED:
      return {
        ...state,
        safetyCentreHistory: action.safetyCentreHistory
      }

    default:
      return state;
  }
};

export default configurationReducer;
