import { auth } from './auth';
import axios from 'axios';
import { getClientId } from './client';
import { toast } from "react-toastify";

export const get = async (url, params) => {
  return request('get', url, null, params);
};

export const post = async (url, data) => {
  return request('post', url, data);
};

export const put = async (url, data) => {
  return request('put', url, data);
};

export const patch = async (url, data) => {
  return request('patch', url, data);
};

export const del = async (url) => {
  return request('delete', url);
};

const request = async (method, url, data, params) => {
  try {
    const response = await axios({
      url,
      method,
      params,
      data,
      headers: await headers(),
    });

    return response.data;
  } catch (e) {
    const response = e.response;

    if (!response) {
      // network error
      console.log('there was an error communicating with the server');
      throw new Error('There was an error communicating with the server');
    }

    if (response.status === 500 || response.status === 502 || response.status === 503) {
      console.log(`${response.statusText}: ${response.data.message}`);

      toast.error(response.data.message || `${response.statusText ? response.statusText + ":" : ""} Unable to complete request`, { position: toast.POSITION.BOTTOM_CENTER });
      throw new Error('The server encountered an error while processing your request');
    }

    if (response.status === 400) {
      const data = e.response?.data;
      const message = (data?.message) || 'Bad Request';
      const errors = (data?.errors) || null;
      const userFacingMessage = errors && Array.isArray(errors) ? `${message}: ${errors[0]}` : message;
      const err = new ValidationError(message);
      err.errors = errors;

      toast.error(userFacingMessage, { position: toast.POSITION.BOTTOM_CENTER });
      throw err;
    }

    // authentication failure, force login
    if (response.status === 401 || response.status === 403) {
      if (!auth) {
        throw new Error('Authentication configuration not available');
      } else {
        auth.signOut('/');
      }
    }
  }
};

// custom error
class ValidationError extends Error {
  constructor(message) {
    super(message);
    this.name = 'ValidationError';
  }
}

const headers = async () => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'client-id': getClientId()
  };

  // provide token if we have auth set up
  if (auth) {
    const token = await auth.getAccessToken();
    headers['Authorization'] = 'Bearer ' + token;
  }

  return headers;
};