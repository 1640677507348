import React from "react";
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { styled } from '@mui/material/styles';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { addHours, addMonths, isAfter } from "date-fns";

import { TargetedVehicleForm, WhitelistVehicleForm, TestVehicleForm, calculateExpiryDate } from './Forms';
import Map from './Map'
import CsvUpload from './CsvUpload';
import { VmsButton, VmsUploadButton } from "./Button";

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const Title = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: theme.palette.primary.background,
  color: theme.palette.primary.text
}));

export const MapModal = ({ open, handleClose, safetyCentre }) => (
  <Dialog
    open={open}
    onClose={handleClose}
    TransitionComponent={Transition}
    keepMounted
    sx={{
      "& .MuiDialog-container": {
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: "900px",
        },
      },
    }}
  >
    <Title variant="h2">Approach Map</Title>
    <DialogContent sx={{ padding: 0 }}>
      <Map safetyCentre={safetyCentre} />
    </DialogContent>
  </Dialog>
);

export const TargetedVehicleModal = ({
  open,
  formType,
  targetedVehicleDefaultValues,
  title,
  disabledPlate,
  disabledCategory,
  disabledExpiry,
  onCancel,
  onAdd,
  initialValues,
  height
}) => (
  <Dialog
    open={open}
    onClose={onCancel}
    TransitionComponent={Transition}
    aria-describedby="alert-dialog-slide-description"
    sx={{
      "& .MuiDialog-container": {
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: height || "600px",
        },
      },
    }}
  >
    <TargetedVehicleForm 
      title={title}
      formType={formType}
      targetedVehicleDefaultValues={targetedVehicleDefaultValues}
      defaultValues={initialValues}
      disabledPlate={disabledPlate}
      disabledCategory={disabledCategory}
      disabledExpiry={disabledExpiry}
      onAdd={onAdd}
      onCancel={onCancel}
    />
  </Dialog>
);

export const WhitelistVehicleModal = ({
  open,
  title,
  targetedVehicleDefaultValues,
  disabledPlate,
  disabledCategory,
  disabledSubcategories,
  disabledExpiry,
  onCancel,
  onAdd,
  initialValues,
  height
}) => (
  <Dialog
    open={open}
    onClose={onCancel}
    TransitionComponent={Transition}
    sx={{
      "& .MuiDialog-container": {
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: height ? height : "600px",
        },
      },
    }}
  >
    <WhitelistVehicleForm 
      title={title}
      targetedVehicleDefaultValues={targetedVehicleDefaultValues}
      defaultValues={initialValues}
      disabledPlate={disabledPlate}
      disabledCategory={disabledCategory}
      disabledSubcategories={disabledSubcategories}
      disabledExpiry={disabledExpiry}
      onAdd={onAdd}
      onCancel={onCancel}
    />
  </Dialog>
);

export const TestVehicleModal = ({
  open,
  title,
  targetedVehicleDefaultValues,
  disabledPlate,
  disabledCategory,
  disabledSubcategories,
  disabledExpiry,
  onCancel,
  onAdd,
  initialValues,
  height
}) => (
  <Dialog
    open={open}
    onClose={onCancel}
    TransitionComponent={Transition}
    sx={{
      "& .MuiDialog-container": {
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: height || "600px",
        },
      },
    }}
  >
    <TestVehicleForm 
      title={title}
      targetedVehicleDefaultValues={targetedVehicleDefaultValues}
      defaultValues={initialValues}
      disabledPlate={disabledPlate}
      disabledCategory={disabledCategory}
      disabledSubcategories={disabledSubcategories}
      disabledExpiry={disabledExpiry}
      onAdd={onAdd}
      onCancel={onCancel}
    />
  </Dialog>
);

export const BulkUploadModal = ({
  open,
  title,
  onCancel,
  count,
  setCount,
  error,
  setError,
  parseTargetedVehicleCsv,
  setParsedUploadData,
  parsedUploadData,
  bulkUploadTargetedVehicles,
  loading,
}) => (
  <Dialog
    open={open}
    onClose={onCancel}
    TransitionComponent={Transition}
    sx={{
      "& .MuiDialog-container": {
        "& .MuiPaper-root": {
          minWidth: "1300px",
        },
      },
    }}
  >
    <Title variant="h2">{title}</Title>
    <DialogContent>
        { loading ? 
          <p>LOADING.....</p> :
          <CsvUpload count={count} error={error} />
        }
    </DialogContent>
    <DialogActions>
      {
        !!parsedUploadData ? 
        <>
          <VmsButton text="Cancel" color="secondary" onClick={onCancel} />
          <VmsButton text="Submit" color="secondary" onClick={bulkUploadTargetedVehicles} />
        </> : 
        <>
          <VmsButton text="Cancel" color="secondary" onClick={onCancel} />
          <VmsUploadButton 
            text="Upload" 
            parseTargetedVehicleCsv={parseTargetedVehicleCsv} 
            setParsedUploadData={setParsedUploadData} 
            setError={setError}
            setCount={setCount}
            disabled={!!error}
          />
        </>
      }
      </DialogActions>
  </Dialog>
);

export const SelectSafetyCenterModal = ({ open, onCancel, title, height, children }) => {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      TransitionComponent={Transition}
      keepMounted
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: height ? height : "400px",
          },
        },
      }}
    >
      <Title variant="h2">{title}</Title>
      <DialogContent sx={{ padding: 0 }}>
        {children}
      </DialogContent>
    </Dialog>
  );
};

const handleEntered = () => {
  // The `autoFocus` is not used because, if used, the same Enter that saves
  // the cell triggers "No". Instead, we manually focus the "No" button once
  // the dialog is fully open.
  // noButtonRef.current?.focus();
};

export const SafetyCentreParameterConfirmDialog = ({ type, promiseArguments, handleYes, handleNo, noButtonRef }) => {
  if (!promiseArguments || !promiseArguments[type]) {
    return null;
  }

  return (
    <Dialog
      maxWidth="xs"
      TransitionProps={{ onEntered: handleEntered, in: true }}
      open={!!promiseArguments[type]}
    >
      <DialogTitle variant="h4">Are you sure?</DialogTitle>
      <DialogActions>
        <Button ref={noButtonRef} onClick={() => handleNo(type)}>No</Button>
        <Button onClick={() => handleYes(type)}>Yes</Button>
      </DialogActions>
    </Dialog>
  );
};

export const SafetyCentreHistoryConfirmDialog = ({ open, handleYes, handleNo }) => {
  const noButtonRef = React.useRef(null);

  if (!open) {
    return null;
  }

  return (
    <Dialog
      maxWidth="xs"
      TransitionProps={{ onEntered: handleEntered, in: true }}
      open={open}
    >
      <DialogTitle variant="h4">Are you sure?</DialogTitle>
      <DialogActions>
        <Button ref={noButtonRef} onClick={handleNo}>No</Button>
        <Button onClick={handleYes}>Yes</Button>
      </DialogActions>
    </Dialog>
  );
};

export const SettingsConfirmDialog = ({ promiseArguments, handleYes, handleNo }) => {
  const noButtonRef = React.useRef(null);

  const exceedMaxTime = () => {
    const { newRow } = promiseArguments;
    if (newRow.key === "TARGETED" || newRow.key === "RECALL" || newRow.key === "TARGETED_TSL") {
      const expDate = calculateExpiryDate({ timeUnit: newRow.timeUnit, value: newRow.value});
      const invalid = isAfter(expDate, addMonths(new Date(), 6));
      if (invalid) {
        return true;
      }
    }

    if (newRow.key === "TEST_VEHICLE") {
      const expDate = calculateExpiryDate({ timeUnit: newRow.timeUnit, value: newRow.value});
      const invalid = isAfter(expDate, addHours(new Date(), 12));
      if (invalid) {
        return true;
      }
    }

    return false;
  };

  if (!promiseArguments) {
    return null;
  }

  return (
    <Dialog
      maxWidth="xs"
      TransitionProps={{ onEntered: handleEntered, in: true }}
      open={!!promiseArguments}
    >
      {
        !exceedMaxTime() ?
        <div>
          <DialogTitle variant="h4">Are you sure?</DialogTitle>
          <DialogActions>
            <Button ref={noButtonRef} onClick={handleNo}>No</Button>
            <Button onClick={handleYes}>Yes</Button>
          </DialogActions>
        </div>
        :
        <div>
          <DialogTitle variant="h4">Default date greater than API limit</DialogTitle>
          <DialogActions>
            <Button ref={noButtonRef} onClick={handleNo}>Cancel</Button>
          </DialogActions>
        </div>
      }
    </Dialog>
  );
};