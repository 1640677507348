import {
  CLEAR_VEHICLE_SEARCH_RESULTS,
  EXECUTE_VEHICLE_SEARCH,
  RECEIVE_VEHICLE_SEARCH_RESULTS
} from '../action-types';

const initialState = {};

const vehicleSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case EXECUTE_VEHICLE_SEARCH:
      return {
        ...state,
        query: action.query
      };

    case CLEAR_VEHICLE_SEARCH_RESULTS:
      return {
        ...state,
        items: undefined,
        cursor: ''
      };
    case RECEIVE_VEHICLE_SEARCH_RESULTS:
      return {
        ...state,
        items: (state.items || []).concat(action.items),
        cursor: action.cursor
      };
    default:
      return state;
  }
};

export default vehicleSearchReducer;
