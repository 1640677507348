import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';

import { AssessmentDetail } from '@components/AssessmentDetail';
import Navbar from "@components/Navbar";
import Header from '@components/Header';
import Passages from './Passages';
import SafetyCentreSelection from './SafetyCentreSelection';
import SafetyCentreConfig from './SafetyCentreConfig';
import SafetyCentres from './SafetyCentres';
import Search from './Search';
import TargetedVehicles from './TargetedVehicles';
import Settings from './Settings';
import { auth } from '../auth';
import {
  restoreSafetyCentreSelection,
  setClientConnection,
} from '@actions';
import { setSafetyCentreIdCookies } from '../client';
import SafetyCentreHistory from './SafetyCentreConfigHistory';
import FullPageLoader from '@components/Loader';
import { getGlobalParameters } from '@actions';
import { querySafetyCentre } from 'src/redux/actions';

function SetSafetyCentre({ match }) {
  console.log('setting id to ', match.params.scid);
  setSafetyCentreIdCookies(match.params.scid);
  return null;
}

const Main = () => {
  const user = useSelector(state => state.user);
  const safetyCentre = useSelector(state => 
    state.configuration.selected === undefined
    ? undefined
    : state.configuration.selected &&
      state.configuration.safetyCentres[state.configuration.selected]
    ? state.configuration.safetyCentres[state.configuration.selected]
    : null
  );
  const selected = useSelector(state => state.configuration.selected);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let clientInterval;
  let safetyCenterApproachStatusInterval;

  const [menuOpen, setMenu] = useState(false);

  useEffect(() => {
    if (user.isEnforcementOfficer) {
      dispatch(restoreSafetyCentreSelection());
      dispatch(setClientConnection());
      dispatch(getGlobalParameters());
      // eslint-disable-next-line
      clientInterval = window.setInterval(setClientConnection(), 14500);
    }
    return () => {
      clientInterval && window.clearInterval(clientInterval);
    }
  }, []);

  useEffect(() => {
    // eslint-disable-next-line
    safetyCenterApproachStatusInterval = window.setInterval(querySafetyCentre(selected), 5000);

    return () => {
      safetyCenterApproachStatusInterval && window.clearInterval(safetyCenterApproachStatusInterval);
    }
  }, [safetyCentre, selected])
  
  const toggleMenu = () => {
    setMenu(!menuOpen);
  };

  const logout = () => {
    auth.signOut(window.location.origin + "/logged-out");
    navigate(`/logged-out`)
  }
  
  const title = (safetyCentre && safetyCentre.name) || 'Vehicle Screening';  

  return (
    <Box maxHeight="100%">
      <Header className="sticky top-0" title={title} onMenuClick={toggleMenu} />

      {user.isEnforcementOfficer && !selected && (
        <SafetyCentreSelection />
      )}

      <Routes>
        <Route path="*" element={<FullPageLoader open disableSpinner message="Page not found please check URL or return to previous page" />} />
        <Route path="/safety-centres" element={<SafetyCentres />} />
        <Route path="safety-centres/:scid/passages" element={<Passages />} />
        <Route path="/safety-centres/:scid/config" element={<SafetyCentreConfig />} /> 
        <Route path="/safety-centres/:scid/:approachId/history" element={<SafetyCentreHistory />} /> 
        <Route path="/targeted-vehicles" element={<TargetedVehicles />} />
        <Route path="/assessments/:mcid" element={<AssessmentDetail hideCloseButton={true} renderHeader/>} />
        <Route path="/assessments" element={<Search />} />
        <Route path="/safety-centres/configure/:scid" element={<SetSafetyCentre />} />
        <Route path="/settings" element={<Settings />} />

        <Route
          path="/"
          element={
            (user.isEnforcementOfficer && !!selected) ? 
              <Navigate to={`/safety-centres/${selected}/passages`} replace={true}/> :
            (!user.isEnforcementOfficer && user.isSystemMaintainer) ? 
              <Navigate to={`/safety-centres`} replace={true} /> : 
              null
          }
        />

      </Routes>

      <Navbar className="z-100" open={menuOpen} onMenuClick={toggleMenu} logout={logout} />
      
    </Box>
  );
}

export default Main;
