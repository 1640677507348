import { useForm, useWatch } from 'react-hook-form';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { yupResolver } from '@hookform/resolvers/yup';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { Title } from '../Modal';
import { testVehicleSchema } from '@helpers';
import * as Forms from './index';
import { addDays } from 'date-fns';

const TestVehicleForm = ({ 
  defaultValues, title, targetedVehicleDefaultValues, onCancel, onAdd, disabledCategory, disabledSubcategories, disabledPlate, disabledExpiry 
}) => {

  const { handleSubmit, reset, control, formState, setValue } = useForm({
    defaultValues,
    mode: 'all',
    resolver: yupResolver(testVehicleSchema),
  });

  const { isValid, errors } = formState;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Title variant="h2">{title}</Title>
      <div className="flex w-full p-8 max-w-screen-md justify-start items-start">
        <form className="w-full" autoComplete='off' onSubmit={handleSubmit((_data) => onAdd(_data))} >

          <DialogContent sx={{ padding: 0 }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Forms.NumberPlateWhiteListAndTestInput control={control} errors={errors} defaultValues={defaultValues} disabledPlate={disabledPlate} title="Number Plate" />
              <Forms.StartInput control={control} errors={errors} disabledStart={disabledExpiry} />
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Forms.CategoryInput 
                control={control} 
                errors={errors} 
                defaultValues={defaultValues} 
                disabledCategory={disabledCategory} 
                options={Forms.testVehicleCategoryOptions} 
              />

              <div style={{ flex: 1 }}>
                <Forms.CategoryWatchedExpiry 
                  type={"test_vehicle"}
                  control={control} 
                  useWatch={useWatch}
                  setValue={setValue}
                  errors={errors}
                  targetedVehicleDefaultValues={targetedVehicleDefaultValues}
                  defaultValue={defaultValues?.expiry}
                  disabledExpiry={disabledExpiry}
                  maxDate={addDays(new Date(), 1)}
                />
              </div>
            </div>

            <div style={{ display: "flex", flex: 1 }}>
              <Forms.SubcategoriesInput 
                control={control} 
                errors={errors} 
                defaultValues={defaultValues} 
                disabledSubcategories={disabledSubcategories} 
                options={Forms.testVehicleSubcategoriesOptions}
              />
            </div> 

            <Forms.ReasonInput control={control} errors={errors} defaultValue={defaultValues?.reason ? defaultValues.reason : ""} />

            <Typography variant="h3" className="mt-11 pt-6 border-t-2">Requester Details</Typography>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Forms.RequesterNameInput control={control} errors={errors} defaultValue={defaultValues?.requesterName} />

              <Forms.RequesterEmailInput control={control} errors={errors} defaultValue={defaultValues?.requesterEmail} />
            </div>
          </DialogContent>

          <DialogActions>
            <Forms.CancelButton 
              handleCancel={() => {
                reset(defaultValues);
                onCancel();
              }} 
            />

            <Forms.SubmitButton isValid={isValid} />

          </DialogActions>
        </form>
      </div>
    </LocalizationProvider>
  );
}

export default TestVehicleForm;