import React from 'react';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

import { IconCheck } from '@icons';

export const RecallConfirmationDialogue = ({open, plate, toggleRecallDialogue}) => {
  const RecallDialogue = styled("div")`
    background-color: #e6f3eb;
    border: 1px solid #76be93;
    margin: 15px;
    padding: 5px;
    border-radius: 4px;
    display: ${!open ? "none" : "flex"};
    justify-content: space-between;
    align-items: center;
  `; 

  const InfoContainer = styled("div")`
    display: flex; 
    justify-content: flex-start; 
    height: 1rem; 
    width: 500px;
    align-items: center;
  `;
  const SecondInfoContainer = styled("div")`
    display: flex; 
    justify-content: right; 
    width: 100%;
    align-items: center;
  `;
  const InlineButton = styled("button")`
    padding: 0;
    border: none;
    background: none;
  `;

  return (
    <RecallDialogue>
      <InfoContainer >
        <IconCheck color="success" />
        <p>&nbsp;&nbsp;{plate}&nbsp;&nbsp;has been marked for recall</p>
      </InfoContainer>
      <SecondInfoContainer >
        <InlineButton onClick={() => toggleRecallDialogue(!open)}><CloseIcon /></InlineButton>
      </SecondInfoContainer>
    </RecallDialogue>
  )
};